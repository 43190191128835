import { useState, useEffect } from 'react';
import useAxios from 'axios-hooks';
import DateUtils, { FORMAT } from '../utils/dateUtils';

const useAvailableDates = ({ shouldExecute, startDate, endDate, roomId }) => {
  const [availableDates, setAvailableDates] = useState({});
  const [loading, execute] = useAxios(
    {
      url: '/api/listAvailableDates',
      method: 'POST'
    },
    { manual: true }
  );

  const refetch = () => {
    if (!shouldExecute || !startDate || !endDate || !roomId) {
      return;
    }

    execute({ data: { startDate, endDate, roomId } }).then(({ data = [] }) => {
      setAvailableDates(
        data.reduce(
          (obj, { date, available }) => ({
            ...obj,
            [DateUtils.formatDate(date, FORMAT)]: available
          }),
          {}
        )
      );
    });
  };

  useEffect(refetch, [shouldExecute, startDate, endDate, roomId]);

  return { loading, availableDates };
};

export default useAvailableDates;
