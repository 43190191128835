import React from 'react';
import { createUseStyles } from 'react-jss';
import Colors from '../../utils/colors';
import { TASK_LABELS } from '../../utils/constants';

const TASK_LABEL_COLORS = {
  [TASK_LABELS.OWNER]: Colors.lightBlueTwo,
  [TASK_LABELS.GUEST_OF_OWNER]: Colors.greenTwo,
  [TASK_LABELS.RENT]: Colors.renterEvent,
  [TASK_LABELS.HOLD]: Colors.groupHoldEvent,
  [TASK_LABELS.MANDATORY]: Colors.redOne,
  [TASK_LABELS.REQUESTED]: Colors.greenOne
};

const useStyles = createUseStyles(
  {
    root: type => ({
      display: 'inline-block',
      backgroundColor: TASK_LABEL_COLORS[type] || Colors.grayOne,
      color: Colors.whiteOne,
      borderRadius: 4,
      fontSize: 12,
      padding: '2px 4px',
      fontWeight: 500
    })
  },
  { name: 'TaskLabel' }
);

const TaskLabel = ({ type }) => {
  const classes = useStyles(type);

  if (!type) {
    return null;
  }

  return (
    <div className={classes.root}>
      {type.replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase()}
    </div>
  );
};

export default React.memo(TaskLabel);
