import React from 'react';
import { Link } from 'react-router-dom';
import BasicLayout from '../layouts/BasicLayout';

const NotFoundView = () => (
  <BasicLayout>
    <h1>404 - Ooops, Something Went Wrong</h1>
    <Link to="/">Back to Home</Link>
  </BasicLayout>
);

export default NotFoundView;
