import enUS from 'date-fns/locale/en-US';
import moment from './moment';

export const FORMAT = 'YYYY-MM-DD';
export const EN_US = enUS;

const parseTime = time => moment(time, 'hh:mm').format('hh:mm A');
const decodeTime = time => moment(time, 'hh:mmA').format('hh:mm');
const isAnotherYear = (date, year) => moment(date).format('YYYY') !== year;
const defaultFormat = date => moment(date).format(FORMAT);
const toDate = date => moment(date).toDate();
const formatDate = (date, format = 'MMM Do, yyyy') =>
  moment(date).format(format);
const formatDate1 = (date, format = 'yyyy-MM-DDTHH:mm:ss.ssssssZZ') =>
  moment(date).format(format);
const subtractDay = date => moment(date).subtract(1, 'days');
const addDay = date => moment(date).add(1, 'days');
const getNumberOfNights = (start, end) =>
  moment(end).diff(moment(start), 'days');
const getDaysInBetween = (start, end) => {
  const startDate = moment(start);
  const endDate = moment(end);
  const dates = [];

  if (!startDate || !endDate) {
    return dates;
  }

  for (
    let date = moment(startDate);
    date.isBefore(endDate);
    date.add(1, 'days')
  ) {
    dates.push(date.format(FORMAT));
  }

  dates.push(endDate.format(FORMAT));
  return dates;
};

export default {
  defaultFormat,
  formatDate,
  formatDate1,
  parseTime,
  decodeTime,
  isAnotherYear,
  subtractDay,
  addDay,
  getNumberOfNights,
  toDate,
  getDaysInBetween
};
