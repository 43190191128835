import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import Colors from '../utils/colors';

const useStyles = createUseStyles(
  {
    root: {
      margin: '0.5rem 0',
      backgroundColor: Colors.whiteTwo,
      borderRadius: 4,
      width: '100%',
      height: 5
    },
    progress: {
      width: '0%',
      height: '100%',
      borderRadius: 4,
      backgroundColor: 'transparent',
      transition: 'width 500ms linear'
    }
  },
  { name: 'ProgressLine' }
);

const ProgressLine = ({ progress, color }) => {
  const [ready, setReady] = useState(false);
  const classes = useStyles();

  setTimeout(() => setReady(true), 500);

  return (
    <div className={classes.root}>
      <div
        className={classes.progress}
        style={
          ready
            ? {
                backgroundColor: color,
                width: progress
              }
            : {}
        }
      />
    </div>
  );
};

export default React.memo(ProgressLine);
