import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { Dimmer, Loader } from 'semantic-ui-react';
import useAxios from 'axios-hooks';
import { orderBy } from 'lodash';
import Statement from '../../components/Statement';
import Box from '../../components/Box';
import Colors from '../../utils/colors';
import useOwnerProtection from '../../hooks/useOwnerProtection';

const useStyles = createUseStyles(
  {
    root: {
      flex: 1,
      padding: '2rem',
      '& > *:not(:last-child)': {
        marginBottom: `2rem`
      }
    },
    statements: {
      minHeight: 400,
      '& > *:not(:last-child)': {
        borderBottom: `1px solid ${Colors.grayTwo}`
      }
    }
  },
  { name: 'OwnerStatementsView' }
);

const OwnerStatementsView = () => {
  useOwnerProtection();

  const classes = useStyles();
  const [{ loading, data }, execute] = useAxios(
    {
      url: '/api/statementHistory',
      method: 'GET'
    },
    { manual: true }
  );

  useEffect(execute, []);

  const statements = orderBy(data, ['endDate'], ['desc']);

  return (
    <div className={classes.root}>
      <h1>Statements</h1>
      <Box title="Statement History">
        <Dimmer.Dimmable dimmed={loading}>
          <Dimmer active={loading} inverted>
            <Loader inverted content="Loading Statements..." />
          </Dimmer>
          <div className={classes.statements}>
            {statements?.map(({ id, endDate, description }) => (
              <Statement
                key={id}
                id={id}
                description={description}
                endDate={endDate}
              />
            ))}
          </div>
        </Dimmer.Dimmable>
      </Box>
    </div>
  );
};

export default React.memo(OwnerStatementsView);
