import React, { useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import ModalForm from '../ModalForm';
import OwnerReservationForm from './OwnerReservationForm';
import GuestOfOwnerReservationForm from './GuestOfOwnerReservationForm';
import useModalState from '../../hooks/useModalState';
import { RESERVATION_TYPES } from '../../utils/constants';

const initialState = {
  type: RESERVATION_TYPES.OWNER,
  reservation: null
};

const RESERVATION_FORMS = {
  [RESERVATION_TYPES.OWNER]: OwnerReservationForm,
  [RESERVATION_TYPES.GUEST_OF_OWNER]: GuestOfOwnerReservationForm
};

const CreateReservationModal = ({ onCreate }) => {
  const { isOpen, onOpen, onClose } = useModalState();
  const [state, setState] = useState(initialState);
  const { type } = state;
  const ReservationForm = RESERVATION_FORMS[type];

  const onSuccess = ({ data }) => {
    onCreate(data.checkIn);
    onClose();
  };

  const onChangeType = (_event, { value }) =>
    setState({ ...state, type: value });

  return (
    <ModalForm.Content
      height="95%"
      size="fullscreen"
      centered={false}
      open={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      trigger={
        <Button size="mini" icon primary>
          <Icon name="plus" /> Add reservation
        </Button>
      }
      title="Create Reservation"
    >
      <ReservationForm
        onClose={onClose}
        onSuccess={onSuccess}
        onChangeType={onChangeType}
      />
    </ModalForm.Content>
  );
};

export default React.memo(CreateReservationModal);
