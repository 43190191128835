import React from 'react';
// import { Form } from 'semantic-ui-react';
// import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
// import { createUseStyles } from 'react-jss';
// import { EN_US, FORMAT } from '../../utils/dateUtils';
import { List } from 'semantic-ui-react';

/* const useStyles = createUseStyles(
  {
    root: {
      '& .field': {
        width: '100%'
      }
    }
  },
  { name: 'ReadOnlyDateField' }
); */

const ReadOnlyDateField = ({ value }) => {
  // const classes = useStyles();
  console.log('test');

  return (
    <List verticalAlign="top">
      <List.Item image="/assets/date.svg" content={value} />
    </List>
  );
};

export default React.memo(ReadOnlyDateField);
