import React from 'react';
import useAxios from 'axios-hooks';
import TaskItem from './TaskItem';
import { RESERVATION_TYPES, TASK_LABELS } from '../../utils/constants';
import DateUtils from '../../utils/dateUtils';

const ReservationTask = ({
  reservation,
  user,
  onUpdate: onUpdateSuccess,
  onDelete: onDeleteSuccess,
  onExclude: onExcludeSuccess
}) => {
  const {
    id,
    reservationRoomId,
    reservationTitle,
    reservationType,
    title,
    ownerReservation,
    guestOfOwnerReservation,
    groupHold,
    ...props
  } = reservation;

  const label = TASK_LABELS[reservationType];

  const [, updateReservation] = useAxios(
    {
      url: '/api/updateReservation',
      method: 'POST'
    },
    { manual: true }
  );

  const [, cancel] = useAxios(
    {
      url: '/api/cancelReservation',
      method: 'POST'
    },
    { manual: true }
  );

  const [, excludeHousekeepingTask] = useAxios(
    {
      url: '/api/excludeHousekeepingTask',
      method: 'POST'
    },
    { manual: true }
  );

  const onSubmit = ({
    checkIn,
    checkOut,
    arrivalTime,
    roomId,
    notes,
    guestFirstName,
    guestLastName,
    guestEmail,
    guestPhone
  }) => {
    if (reservationType !== RESERVATION_TYPES.GUEST_OF_OWNER) {
      guestFirstName = null;
      guestLastName = null;
      guestEmail = null;
      guestPhone = null;
    }
    const payload = {
      id,
      reservationRoomId,
      checkIn,
      checkOut,
      arrivalTime: DateUtils.parseTime(arrivalTime),
      roomId,
      notes,

      guestFirstName,
      guestLastName,
      guestEmail,
      guestPhone
    };
    return new Promise((resolve, reject) =>
      updateReservation({ data: payload })
        .then(({ data }) => {
          resolve({
            ...data,
            title,
            label,
            user
          });
          onUpdateSuccess(data.checkIn);
        })
        .catch(error => {
          reject(error);
        })
    );
  };

  const onDelete = () => {
    const payload = { reservationId: id };

    return new Promise((resolve, reject) =>
      cancel({ data: payload })
        .then(() => {
          resolve();
          onDeleteSuccess(reservation.start);
        })
        .catch(error => {
          reject(error);
        })
    );
  };

  const onExclude = taskId => {
    const payload = {
      reservationId: reservationRoomId,
      includedTaskId: taskId
    };
    return new Promise((resolve, reject) =>
      excludeHousekeepingTask({ data: payload })
        .then(({ data }) => {
          resolve({
            ...data,
            title,
            label,
            user
          });
          onExcludeSuccess(DateUtils.toDate(DateUtils.addDay(data.checkIn)));
          onExcludeSuccess(data.checkIn);
        })
        .catch(error => {
          reject(error);
        })
    );
  };

  const {
    start,
    end,
    arrivalTime,
    notes,
    roomId,
    description,
    guestEmail,
    guestPhone,
    taskIncludedList
  } = props;

  return (
    <TaskItem
      id={id}
      title={reservationTitle}
      description={description}
      label={label}
      user={user}
      checkIn={start}
      checkOut={end}
      arrivalTime={arrivalTime}
      notes={notes}
      roomId={roomId}
      guestEmail={guestEmail}
      guestPhone={guestPhone}
      taskIncludedList={taskIncludedList}
      onSubmit={onSubmit}
      onDelete={onDelete}
      onExclude={onExclude}
      readOnly={
        reservation.reservationType !== RESERVATION_TYPES.OWNER &&
        reservation.reservationType !== RESERVATION_TYPES.GUEST_OF_OWNER
      }
      taskItemType={reservationType}
    />
  );
};

export default React.memo(ReservationTask);
