import React from 'react';
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';
import { Button, Input, Form } from 'semantic-ui-react';
import Colors from '../../utils/colors';
import { RESERVATION_TYPES } from '../../utils/constants';

const { OWNER, GUEST_OF_OWNER } = RESERVATION_TYPES;

const button = {
  '&.ui.button': {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  }
};

const span = {
  display: 'inline-block',
  margin: '0 10px',
  borderRadius: 4,
  width: 16,
  height: 16
};

const useStyles = createUseStyles(
  {
    root: {
      '& > .ui.input': {
        display: 'flex'
      }
    },
    owner: {
      extend: button,
      '& > span': {
        extend: span,
        backgroundColor: Colors.lightBlueTwo
      }
    },
    guestOfOwner: {
      extend: button,
      marginLeft: '1rem !important',
      '& > span': {
        extend: span,
        backgroundColor: Colors.greenTwo
      }
    },
    active: {
      border: `2px solid ${Colors.greenOne} !important`
    }
  },
  { name: 'ChangeReservationType' }
);

const ChangeReservationType = ({ type, onChange }) => {
  const classes = useStyles(type);

  return (
    <Form.Field
      id="type"
      className={classes.root}
      control={Input}
      label="Type"
      type="hidden"
      value={type}
    >
      <Button
        className={classnames('white', classes.owner, {
          [classes.active]: type === OWNER
        })}
        value={OWNER}
        type="button"
        onClick={onChange}
      >
        <span />
        Owner
      </Button>
      <Button
        className={classnames('white', classes.guestOfOwner, {
          [classes.active]: type === GUEST_OF_OWNER
        })}
        value={GUEST_OF_OWNER}
        type="button"
        onClick={onChange}
      >
        <span />
        Guest of Owner
      </Button>
    </Form.Field>
  );
};

export default React.memo(ChangeReservationType);
