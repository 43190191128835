import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import {
  Button,
  Modal,
  Icon,
  Dimmer,
  Loader,
  Message
} from 'semantic-ui-react';

const useStyles = createUseStyles(
  {
    actions: {
      display: 'flex',
      justifyContent: 'center',
      '& > *': {
        flexGrow: 1,
        minWidth: 148,
        height: 36
      }
    }
  },
  { name: 'ConfirmationModal' }
);

const ConfirmationModal = ({
  header,
  trigger,
  onConfirm,
  size = 'mini',
  texts,
  children
}) => {
  const classes = useStyles();
  const [state, setState] = useState({
    error: null,
    open: false,
    loading: false
  });

  const onClickTrigger = () =>
    setState({ error: null, open: true, loading: false });

  const onClickClose = () =>
    setState({ error: null, open: false, loading: false });

  const onError = error =>
    setState({
      error: error.response?.data?.message,
      open: true,
      loading: false
    });

  const onClickConfirm = () => {
    setState({ open: true, loading: true });
    onConfirm().then(onClickClose).catch(onError);
  };

  const { open, loading, error } = state;
  const Trigger = trigger;

  return (
    <Modal
      open={open}
      size={size}
      trigger={<Trigger onClick={onClickTrigger} />}
    >
      {!!header && <Modal.Header>{header}</Modal.Header>}
      <Modal.Content>
        <Dimmer.Dimmable dimmed={loading}>
          <Dimmer active={loading} inverted>
            <Loader inverted />
          </Dimmer>
          {!!error && <Message content={error} error />}
          {children}
        </Dimmer.Dimmable>
      </Modal.Content>
      <Modal.Actions className={classes.actions}>
        <Button
          onClick={onClickClose}
          disabled={loading}
          size={size}
          color="red"
        >
          <Icon name="remove" /> {texts?.cancel || 'No'}
        </Button>
        <Button onClick={onClickConfirm} disabled={loading} size={size} primary>
          <Icon name="checkmark" /> {texts?.confirm || 'Yes'}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default React.memo(ConfirmationModal);
