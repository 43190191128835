import StringUtils from './stringUtils';

const validateSchema = (schema, data) => {
  const promise = new Promise((resolve, reject) => {
    schema
      .validateAsync(data, { abortEarly: false, allowUnknown: true })
      .then(resolve)
      .catch(({ details }) => {
        const errors = details.reduce(
          (obj, { message, context: { key } }) => ({
            ...obj,
            [key]: message.replace(key, StringUtils.toTitle(key))
          }),
          {}
        );

        reject(errors);
      });
  });

  return promise;
};

export default validateSchema;
