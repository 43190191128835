import ACTIONS from '../actions/eventsActions';

const initialState = {
  selected: []
};

const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.RESET_SELECTED: {
      return initialState;
    }
    case ACTIONS.SELECT_EVENTS: {
      return {
        ...state,
        selected: action.payload,
        slot: action.selectedSlot
      };
    }
    default: {
      return state;
    }
  }
};

export default eventsReducer;
