import React, { useState } from 'react';
import { Button, Form, Input, Message } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import useAxios from 'axios-hooks';
import Joi from 'joi';
import DatepickerField from './Fields/DatepickerField';
import AUTH_ACTIONS from '../actions/authAction';
import validateSchema from '../utils/validateSchema';
import { RESPONSE_STATUS } from '../utils/constants';
import { userSelector, ownerSelector } from '../selectors';
import DateUtils from '../utils/dateUtils';

const profileSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .min(3)
    .max(255)
    .required()
});

const ProfileForm = ({ className }) => {
  const user = userSelector();
  const dispatch = useDispatch();
  const [message, setMessage] = useState(null);
  const [errors, setErrors] = useState({
    email: null
  });

  const [profile, setProfile] = useState({
    ...user
  });

  const [{ loading }, execute] = useAxios(
    {
      url: '/api/updateOwnerProfile',
      method: 'POST'
    },
    { manual: true }
  );

  const { roomInfoList } = ownerSelector();

  const onError = error => {
    let errorMessage = 'Please, try again later';
    if (error.response.data) {
      errorMessage = error.response.data;
    }
    setMessage({
      header: 'Something Went Wrong',
      content: errorMessage,
      error: true
    });
  };

  const onSuccess = ({ status }) => {
    if (status === RESPONSE_STATUS.OK) {
      setMessage({
        header: 'Success',
        content: 'Your profile has been updated',
        success: true
      });
      setErrors({
        email: null
      });
      setProfile({
        ...profile
      });
      dispatch({
        type: AUTH_ACTIONS.UPDATE_USER,
        payload: {
          changePassword: false,
          ...profile
        }
      });
    }
  };

  const onSubmit = () => {
    validateSchema(profileSchema, profile)
      .then(() => {
        execute({
          data: {
            ...profile,
            units: roomInfoList
          }
        })
          .then(onSuccess)
          .catch(onError);
      })
      .catch(setErrors);
  };

  const {
    email,
    otherPhone,
    mobile,
    address,
    city,
    state,
    zip,
    country,
    taxId,
    customerCompany,
    dob
  } = profile;

  const onChange = (_event, { id, value }) => {
    setErrors({ ...errors, [id]: null });
    if (id === 'dob' && !!value) {
      value = DateUtils.defaultFormat(value);
    }
    setProfile({ ...profile, [id]: value });
  };

  return (
    <Form loading={loading} onSubmit={onSubmit} className={className}>
      {!!message && <Message {...message} visible />}
      <Form.Group widths="equal">
        <Form.Field
          id="firstName"
          control={Input}
          label="First Name"
          type="text"
          value={user?.firstName}
          readOnly
        />
        <Form.Field
          id="lastName"
          control={Input}
          label="Last Name"
          type="text"
          value={user?.lastName}
          readOnly
        />
        <Form.Field
          id="email"
          control={Input}
          label="Email"
          type="text"
          value={email}
          onChange={onChange}
          error={errors.email}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          id="mobile"
          control={Input}
          label="Mobile"
          type="text"
          value={mobile}
          onChange={onChange}
        />
        <Form.Field
          id="otherPhone"
          control={Input}
          label="Other Phone"
          type="text"
          value={otherPhone}
          onChange={onChange}
        />
        <Form.Field
          id="address"
          control={Input}
          label="Address"
          type="text"
          value={address}
          onChange={onChange}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          id="city"
          control={Input}
          label="City"
          type="text"
          value={city}
          onChange={onChange}
        />
        <Form.Field
          id="state"
          control={Input}
          label="State"
          type="text"
          value={state}
          onChange={onChange}
        />
        <Form.Field
          id="country"
          control={Input}
          label="Country"
          type="text"
          value={country}
          onChange={onChange}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          id="zip"
          control={Input}
          label="Zip"
          type="text"
          value={zip}
          onChange={onChange}
        />
        <Form.Field
          id="taxId"
          control={Input}
          label="Tax"
          type="text"
          value={taxId}
          onChange={onChange}
        />
        <Form.Field
          id="customerCompany"
          control={Input}
          label="Company"
          type="text"
          value={customerCompany}
          onChange={onChange}
        />
      </Form.Group>
      <Form.Group widths="3">
        <DatepickerField
          id="dob"
          label="DOB"
          placeholder="Select Date"
          value={dob ? DateUtils.toDate(dob) : null}
          onChange={onChange}
          clearable
        />
      </Form.Group>
      <Button type="submit">Save profile</Button>
    </Form>
  );
};

export default React.memo(ProfileForm);
