import React, { useState, useEffect } from 'react';
import { Button, Form, TextArea, Message } from 'semantic-ui-react';
import Joi from 'joi';
import useAxios from 'axios-hooks';
import ModalForm from '../ModalForm';
import ChangeReservationType from './ChangeReservationType';
import RoomSelectField from '../Fields/RoomSelectField';
import DatepickerField from '../Fields/DatepickerField';
import TimepickerField from '../Fields/TimepickerField';
import SchemaErrors from '../SchemaErrors';
import { ownerSelector } from '../../selectors';
import useAvailableDates from '../../hooks/useAvailableDates';
import validateSchema from '../../utils/validateSchema';
import DateUtils, { FORMAT } from '../../utils/dateUtils';
import moment from '../../utils/moment';
import { RESERVATION_TYPES } from '../../utils/constants';

const today = moment();

const reservationSchema = Joi.object({
  checkIn: Joi.date().required(),
  checkOut: Joi.date().greater(Joi.ref('checkIn')).required(),
  arrivalTime: Joi.string().allow(null, ''),
  roomId: Joi.number().required(),
  notes: Joi.string().allow('')
});

const initialState = {
  failed: false,
  errors: {},
  data: {
    checkIn: '',
    checkOut: '',
    arrivalTime: '',
    roomId: null,
    notes: '',
    type: RESERVATION_TYPES.OWNER
  }
};

const OwnerReservationForm = ({ onChangeType, onClose, onSuccess }) => {
  const { roomInfoList } = ownerSelector();
  const onlyOneRoom = roomInfoList?.length === 1;

  const [state, setState] = useState(initialState);
  const [{ loading }, execute] = useAxios(
    {
      url: '/api/createReservation',
      method: 'POST'
    },
    { manual: true }
  );

  const [currentDate, setCurrentDate] = useState(today);
  const currentYear = moment(currentDate).format('YYYY');

  const { loadingAvailableDates, availableDates } = useAvailableDates({
    shouldExecute: onlyOneRoom,
    startDate: moment(currentDate).startOf('year').format(FORMAT),
    endDate: moment(currentDate).endOf('year').format(FORMAT),
    roomId: roomInfoList[0]?.id
  });

  const { failed, errors, data } = state;
  const { type, checkIn, checkOut, arrivalTime = null, roomId, notes } = data;

  useEffect(() => {
    if (onlyOneRoom) {
      setState({
        ...state,
        data: { ...data, roomId: roomInfoList[0]?.id }
      });
    }
  }, []);

  const onError = error => {
    console.error(error);
    setState({ ...state, failed: true });
  };

  const validateDates = () => {
    const promise = new Promise((resolve, reject) => {
      if (onlyOneRoom) {
        const dates = DateUtils.getDaysInBetween(checkIn, checkOut);
        dates.forEach(date => {
          if (!availableDates[date]) {
            reject(new Error('All the dates in between must be available'));
          }
        });
      }

      resolve();
    });

    return promise;
  };

  const onSubmit = () => {
    validateDates()
      .then(() => {
        validateSchema(reservationSchema, data)
          .then(() => {
            execute({
              data: {
                ...data,
                arrivalTime: arrivalTime
                  ? DateUtils.parseTime(arrivalTime)
                  : null
              }
            })
              .then(onSuccess)
              .catch(onError);
          })
          .catch(schemaErrors => {
            setState({ ...state, errors: schemaErrors });
          });
      })
      .catch(({ message }) => {
        setState({ ...state, errors: { dates: message } });
      });
  };

  const onChange = (_event, { id, value }) => {
    setState({
      ...state,
      data: { ...data, [id]: value },
      errors: { ...errors, [id]: null }
    });
  };

  const filterDate = date => {
    if (onlyOneRoom) {
      if (DateUtils.isAnotherYear(date, currentYear)) {
        setCurrentDate(date);
        return false;
      }

      return availableDates[DateUtils.defaultFormat(date)];
    }

    return true;
  };

  return (
    <Form loading={loading} onSubmit={onSubmit} onClose={onClose}>
      <ModalForm.Fields>
        <ChangeReservationType type={type} onChange={onChangeType} />
        <Form.Group widths="equal">
          <DatepickerField
            id="checkIn"
            minDate={DateUtils.toDate(new Date())}
            label="Arrival Date"
            placeholder="Select Date"
            value={checkIn}
            onChange={onChange}
            filterDate={filterDate}
            disabled={loadingAvailableDates}
          />
          <DatepickerField
            id="checkOut"
            minDate={DateUtils.toDate(DateUtils.addDay(checkIn))}
            label="Departure Date"
            placeholder="Select Date"
            disabled={loadingAvailableDates || !checkIn}
            value={checkOut}
            onChange={onChange}
            filterDate={filterDate}
          />
        </Form.Group>
        <TimepickerField
          id="arrivalTime"
          label="Estimated Arrival Time"
          placeholder="Estimated Arrival Time"
          value={arrivalTime}
          onChange={onChange}
        />
        <RoomSelectField
          id="roomId"
          value={roomId}
          onChange={onChange}
          checkIn={checkIn}
          checkOut={checkOut}
          onlyOneRoom={onlyOneRoom}
        />
        <Form.Field
          id="notes"
          control={TextArea}
          rows={5}
          label="Notes"
          placeholder="Notes"
          type="text"
          value={notes}
          onChange={onChange}
        />
        <SchemaErrors errors={errors} />
        <Message
          header="Something Went Wrong"
          content="Please, try again later"
          visible={!!failed}
          error
        />
      </ModalForm.Fields>
      <ModalForm.Actions>
        <Button color="red" disabled={loading} onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit" disabled={loading} primary>
          Save Reservation
        </Button>
      </ModalForm.Actions>
    </Form>
  );
};

export default React.memo(OwnerReservationForm);
