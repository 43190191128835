import React from 'react';
import { compact } from 'lodash';
import { Message } from 'semantic-ui-react';
import StringUtils from '../utils/stringUtils';

const SchemaErrors = ({ errors = {} }) => {
  const visible = !!compact(Object.values(errors)).length;
  return (
    <Message
      error
      header="Something Went Wrong"
      visible={visible}
      list={
        <Message.List
          items={Object.keys(errors).map(
            error =>
              !!errors[error] && (
                <Message.Item
                  key={error}
                  content={
                    <span>
                      <strong>{StringUtils.toTitle(error)}:</strong>
                      &nbsp;{errors[error]}
                    </span>
                  }
                />
              )
          )}
        />
      }
    />
  );
};

export default React.memo(SchemaErrors);
