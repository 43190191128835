import React, { useEffect } from 'react';
import useAxios from 'axios-hooks';
import { Form, Select } from 'semantic-ui-react';
import Joi from 'joi';
import { ownerSelector } from '../../selectors';
import validateSchema from '../../utils/validateSchema';
import DateUtils from '../../utils/dateUtils';
import Selects from '../../utils/selects';

const RoomSelectField = ({
  id,
  value,
  onChange,
  error,
  checkIn,
  checkOut,
  onlyOneRoom = false
}) => {
  const { roomInfoList } = ownerSelector();
  const [{ loading, data = [] }, execute] = useAxios(
    {
      url: '/api/listRoomAvailable',
      method: 'POST'
    },
    { manual: true }
  );

  const getRooms = () => {
    if (onlyOneRoom || !checkIn || !checkOut) {
      return;
    }

    const arrival = DateUtils.defaultFormat(checkIn);
    const departure = DateUtils.defaultFormat(checkOut);
    const schema = Joi.object({
      arrival: Joi.date().less(departure).required(),
      departure: Joi.date().greater(arrival).required()
    });

    validateSchema(schema, { arrival, departure }).then(() => {
      execute({
        data: { arrival, departure, reservationRoomId: null }
      });
    });
  };

  useEffect(getRooms, [checkIn, checkOut]);

  const availableRoomIds = data.reduce((obj, { id: roomId }) => {
    obj[roomId] = true;
    return obj;
  }, {});

  const ownerRooms = roomInfoList.reduce((obj, room) => {
    obj[room.id] = Selects.getRoomAsOption(room);
    return obj;
  }, {});

  let options = [];

  if (onlyOneRoom) {
    options = Object.values(ownerRooms);
  } else {
    if (value) availableRoomIds[value] = true;
    options = Object.keys(availableRoomIds).map(roomId => ownerRooms[roomId]);
  }

  let noRoomsError = null;
  if (options.length === 0 && checkIn && checkOut) {
    noRoomsError = 'No rooms found for the selected dates';
  }

  return (
    <Form.Field
      id={id}
      control={Select}
      label="Unit"
      placeholder={
        loading
          ? 'Loading rooms...'
          : 'Select dates and click here to find available rooms'
      }
      value={value}
      options={options}
      onChange={onChange}
      error={!loading && (noRoomsError || error)}
      loading={loading}
      disabled={loading}
    />
  );
};

export default React.memo(RoomSelectField);
