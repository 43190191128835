import React from 'react';
import { createUseStyles } from 'react-jss';
import { sortBy } from 'lodash';
import Weather from './Weather';
import TaskGroup from './Task/TaskGroup';
import ReservationTask from './Task/ReservationTask';
import moment from '../utils/moment';
import { EVENT_TYPES } from '../utils/constants';
import Breakpoints from '../utils/breakpoints';
import { eventsSelector } from '../selectors';
import PropertyEventTask from './Task/PropertyEventTask';
import MaintenanceTask from './Task/MaintenanceTask';
import FrontDeskNotificationTask from './Task/FrontDeskNotificationTask';
import RateTask from './Task/RateTask';

const {
  RESERVATION,
  DAILY,
  MAINTENANCE,
  ON_PROPERTY,
  FRONT_DESK_NOTIFICATION,
  RATES
} = EVENT_TYPES;

const useStyles = createUseStyles(
  {
    root: {
      flexBasis: '393px',
      padding: '2rem 0'
    },
    content: {
      padding: '0 2rem'
    },
    title: {
      marginBottom: '2rem'
    },
    tasks: {
      marginTop: '2rem',
      maxHeight: '90vh',
      overflowY: 'auto',
      padding: '0 2rem',
      '& > *': {
        marginBottom: '2rem'
      }
    },
    [`@media (max-width: ${Breakpoints.lg}px)`]: {
      root: {
        flexBasis: 'unset'
      },
      tasks: {
        overflowY: 'hidden',
        maxHeight: 'auto'
      }
    }
  },
  { name: 'ReservationPanel' }
);

const filterByType = (items, type) => items.filter(item => item.type === type);

const ReservationPanel = ({ onUpdate, onDelete, onExclude, weather }) => {
  const classes = useStyles();
  const { selected } = eventsSelector();
  const today = moment();
  const reservations = sortBy(filterByType(selected, RESERVATION), ['end']);
  const dailies = filterByType(selected, DAILY);
  const rates = filterByType(selected, RATES).filter(
    rateItem =>
      !!rateItem.allowMinLOSModification || !!rateItem.allowRateModification
  );

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <h5 className={classes.title}>{today.format('dddd, Do MMMM')}</h5>
        <Weather
          title="Today"
          maxTemp={weather?.maxTempF}
          minTemp={weather?.minTempF}
          scale="F"
          icon={weather?.icon}
          description={weather?.description}
        />
      </div>
      <div className={classes.tasks}>
        {reservations.length > 0 && (
          <TaskGroup
            title="Reservation"
            image="/assets/calendar_check_icon.svg"
          >
            <>
              {!reservations.length && (
                <p>Select slots or click on one event on the calendar...</p>
              )}
              {reservations.map(reservation => (
                <ReservationTask
                  key={reservation.reservationRoomId}
                  user={reservation.user}
                  reservation={reservation}
                  onUpdate={onUpdate}
                  onDelete={onDelete}
                  onExclude={onExclude}
                />
              ))}
            </>
          </TaskGroup>
        )}

        {dailies.map(daily => {
          const { activities } = daily;
          const onPropertyActs = filterByType(activities, ON_PROPERTY);
          const maintenanceActs = filterByType(activities, MAINTENANCE);
          const frontDeskNotificationActs = filterByType(
            activities,
            FRONT_DESK_NOTIFICATION
          );

          return (
            <>
              <TaskGroup
                title="On Property Events"
                image="/assets/calendar_star_icon.svg"
                show={!!onPropertyActs.length}
              >
                <>
                  {onPropertyActs.map(act => (
                    <PropertyEventTask
                      key={act.id}
                      title={act.name}
                      date={act.date}
                      description={act.description}
                    />
                  ))}
                </>
              </TaskGroup>
              <TaskGroup
                title="Maintenance"
                image="/assets/maintenance_icon.svg"
                show={!!maintenanceActs.length}
              >
                <>
                  {maintenanceActs.map(act => (
                    <MaintenanceTask
                      key={act.id}
                      title={act.name}
                      label={act.label}
                      date={act.date}
                      description={act.description}
                    />
                  ))}
                </>
              </TaskGroup>
              <TaskGroup
                title="Front Desk Notifications"
                image="/assets/bell_icon.svg"
                show={!!frontDeskNotificationActs.length}
              >
                <>
                  {frontDeskNotificationActs.map(act => (
                    <FrontDeskNotificationTask
                      key={act.id}
                      title={act.name}
                      description={act.description}
                      date={act.date}
                    />
                  ))}
                </>
              </TaskGroup>
            </>
          );
        })}

        {rates.length > 0 && (
          <TaskGroup title="Rates" image="/assets/calendar_check_icon.svg">
            <>
              {rates.map(rate => (
                <RateTask
                  key={rate.ratePlanItemId}
                  rateItem={rate}
                  onUpdate={onUpdate}
                />
              ))}
            </>
          </TaskGroup>
        )}
      </div>
    </div>
  );
};

export default React.memo(ReservationPanel);
