import React, { useState, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { List } from 'semantic-ui-react';
import TaskLabel from './TaskLabel';
import TaskEdit from './TaskEdit';
import IconButtons from '../Buttons/IconButtons';
import EllipsisText from '../EllipsisText';
import ConfirmationModal from '../ConfirmationModal';
import DateUtils from '../../utils/dateUtils';
import { TASK_LABELS, TASK_ITEM_TYPES } from '../../utils/constants';
import { ownerSelector, eventsSelector } from '../../selectors';
import Colors from '../../utils/colors';
import './TaskItem.css';

const VIEW_MODE = 'VIEW_MODE';
const EDIT_MODE = 'EDIT_MODE';

const TASK_ITEM_TYPES_COLORS = {
  [TASK_ITEM_TYPES.RESERVATION_RENTER]: Colors.renterTransparent,
  [TASK_ITEM_TYPES.RESERVATION_OWNER]: Colors.ownerTransparent,
  [TASK_ITEM_TYPES.RESERVATION_GUEST_OF_OWNER]: Colors.guestOfOwnerTransparent,
  [TASK_ITEM_TYPES.MAINTENANCE]: Colors.maintenanceEventTransparent,
  [TASK_ITEM_TYPES.ON_PROPERTY]: Colors.onPropertyEventTransparent,
  [TASK_ITEM_TYPES.FRONT_DESK_NOTIFICATION]: Colors.frontDeskEventTransparent,
  [TASK_ITEM_TYPES.ROOM_BLOCK]: Colors.roomBlockTransparent,
  [TASK_ITEM_TYPES.ROOM_BUFFER]: Colors.roomBlockTransparent,
  [TASK_ITEM_TYPES.RATE]: Colors.rateTransparent,
  [TASK_ITEM_TYPES.HOLD]: Colors.groupHoldEventTransparent
};

const item = {
  paddingTop: 0,
  paddingBottom: '1rem'
};

const useStyles = createUseStyles(
  {
    root: properties => ({
      padding: '1.5rem',
      paddingTop: '1rem',
      paddingLeft: '2rem',
      backgroundColor: TASK_ITEM_TYPES_COLORS[properties.type],
      marginBottom: '5px',
      border: '1px solid #E5E5E5',
      borderRadius: '4px',
      position: 'relative'
    }),
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 0
    },
    content: {
      marginRight: '1rem',
      display: 'inline-flex'
    },
    title: properties => ({
      margin: 0,
      fontWeight: 'bold',
      paddingBottom: properties.withPadding ? '0.5rem' : 0
    }),
    description: {
      margin: '0.5rem 0'
    },
    actions: {
      display: 'inline-flex',
      '& > *:not(:last-child)': {
        marginRight: '0.5rem'
      }
    },
    items: {
      marginTop: '1rem',
      '& > .ui.horizontal.list > .item': {
        extend: item,
        display: 'inline-flex'
      },
      '& > .ui.list > .item': {
        extend: item,
        display: 'flex'
      }
    },
    dateIcons: properties => ({
      fontSize: '19px',
      color: '#372e2e',
      position: 'absolute',
      left: '3px',
      paddingBottom: properties.withPadding ? '0.5rem' : 0,
      paddingTop: '3px',
      '& .sign.out': {
        transform: 'rotate(180deg)'
      }
    }),
    includedTask: {
      marginBottom: '3px'
    }
  },
  { name: 'TaskItem' }
);

const TaskItem = ({
  onSubmit,
  onDelete,
  onExclude,
  label,
  taskItemType,
  readOnly,
  ...props
}) => {
  const isRenter = label === TASK_LABELS.RENT;
  const isRoomBlock = label === TASK_LABELS.ROOM_BLOCK;
  const isRoomBuffer = label === TASK_LABELS.ROOM_BUFFER;

  const classes = useStyles({
    withPadding: isRenter || isRoomBlock || isRoomBuffer,
    type: taskItemType
  });
  const { roomInfoList } = ownerSelector();
  const [state, setState] = useState({ mode: VIEW_MODE, data: props });
  const taskItemRef = useRef();

  const {
    mode,
    data: { title, description, ...extra }
  } = state;
  const {
    id,
    user,
    checkIn,
    checkOut,
    arrivalTime,
    notes,
    roomId,
    date,
    price,
    guestEmail,
    guestPhone
  } = extra;

  const {
    rate,
    minLOS,
    allowMinLOSModification,
    allowRateModification,
    taskIncludedList
  } = props;

  const showExtraData = !!Object.keys(extra).length;
  const isInViewMode = mode === VIEW_MODE;
  const isInEditMode = mode === EDIT_MODE;

  const setMode = newMode => setState({ ...state, mode: newMode });
  const onClose = () => {
    setMode(VIEW_MODE);
  };

  const { slot } = eventsSelector();

  const onEdit = () => {
    setMode(EDIT_MODE);
    taskItemRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const onSuccess = data => {
    setState({ mode: VIEW_MODE, data });
  };

  const makeRoomName = rId => {
    for (let i = 0; i < roomInfoList.length; i += 1) {
      const e = roomInfoList[i];
      if (e.id === rId) {
        return `Unit ${e.roomNumber} - ${e.property.name}`;
      }
    }
    return null;
  };

  const hideDescription = isRoomBlock || isRoomBuffer;
  const content = (
    <>
      {!!label && <TaskLabel type={label} />}
      {!hideDescription && !!description && (
        <p className={classes.description}>{description}</p>
      )}
      {showExtraData && user && (
        <div className={classes.items}>
          {!isRenter && (
            <List verticalAlign="top" style={{ margin: 0 }}>
              <List.Item
                image="/assets/guest.svg"
                content={
                  <EllipsisText>
                    <span>
                      {user.firstName} {user.lastName}
                    </span>
                  </EllipsisText>
                }
              />
            </List>
          )}

          {!isRenter && (!!date || !!price) && (
            <List verticalAlign="top" horizontal>
              {!!date && (
                <List.Item
                  image="/assets/date.svg"
                  content={DateUtils.formatDate(date)}
                />
              )}
              {!!price && (
                <List.Item
                  image="/assets/money_icon.svg"
                  content={`$${price}`}
                />
              )}
            </List>
          )}

          {(!!checkIn || !!checkOut) && (
            <List verticalAlign="top" horizontal>
              {!!checkIn && (
                <List.Item
                  image="/assets/check_in.svg"
                  content={DateUtils.formatDate(checkIn)}
                />
              )}
              {!!checkOut && (
                <List.Item
                  image="/assets/check_out.svg"
                  content={DateUtils.formatDate(checkOut)}
                />
              )}
            </List>
          )}

          <List verticalAlign="top" style={{ margin: 0 }}>
            {!isRenter && arrivalTime !== undefined && (
              <List.Item
                image="/assets/clock.svg"
                content={arrivalTime || 'No Arrival Time'}
              />
            )}
            {!isRenter && !!notes && (
              <List.Item image="/assets/notes.svg" content={notes} />
            )}
            {!!roomId && (
              <List.Item
                image="/assets/bed.svg"
                content={makeRoomName(roomId)}
              />
            )}
            {/* DateUtils.formatDate1(new Date(slot)).slice(0, -5) ===
              checkOut.slice(0, -5) && (
              <div className={classes.dateIcons}>
                <i className="sign out alternate icon"> </i>
              </div>
              ) */}
            {/* DateUtils.formatDate1(new Date(slot)).slice(0, -5) ===
              checkIn.slice(0, -5) && (
              <div className={classes.dateIcons}>
                <i className="sign in alternate icon"> </i>
              </div>
              ) */}
          </List>

          {taskIncludedList !== undefined && taskIncludedList.length > 0 && (
            <div className="included-tasks">
              {taskIncludedList.map(task => (
                <div className={classes.includedTask}>
                  <i className="tasks icon task-icon"> </i> {task.description}{' '}
                  <ConfirmationModal
                    header="Cancel Included task"
                    onConfirm={() => onExclude(task.id)}
                    trigger={IconButtons.Close}
                  >
                    <p>
                      <strong>
                        Are you sure you want to cancel {task.description}?
                      </strong>
                    </p>
                  </ConfirmationModal>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      {taskItemType === 'rate' && (
        <div className={classes.items}>
          <List verticalAlign="top">
            <List.Item
              image="/assets/date.svg"
              content={DateUtils.formatDate(date)}
            />
            {rate !== null && (
              <List.Item
                image="/assets/money_icon.svg"
                content={`Rate: $ ${rate}`}
              />
            )}
            {minLOS !== null && (
              <List.Item
                image="/assets/bed.svg"
                content={`MinLOS: ${minLOS} ${
                  minLOS === 1 ? 'Night' : 'Nights'
                }`}
              />
            )}
          </List>
        </div>
      )}
    </>
  );

  const headerIcons = (
    <>
      {showExtraData && user && (
        <>
          {DateUtils.formatDate1(new Date(slot)).slice(0, -5) ===
            checkOut.slice(0, -5) && (
            <div className={classes.dateIcons}>
              <i className="sign out alternate icon"> </i>
            </div>
          )}
          {DateUtils.formatDate1(new Date(slot)).slice(0, -5) ===
            checkIn.slice(0, -5) && (
            <div className={classes.dateIcons}>
              <i className="sign in alternate icon"> </i>
            </div>
          )}
        </>
      )}
    </>
  );

  return (
    <div ref={taskItemRef} className={classes.root}>
      <div className={classes.header}>
        {headerIcons}
        <EllipsisText className={classes.content}>
          <h5 className={classes.title}>{title}</h5>
        </EllipsisText>
        {!isRenter && !isRoomBlock && !isRoomBuffer && !readOnly && (
          <div className={classes.actions}>
            {isInViewMode && !!onEdit && <IconButtons.Edit onClick={onEdit} />}
            {isInViewMode && !!onDelete && (
              <ConfirmationModal
                header="Delete"
                trigger={IconButtons.Remove}
                onConfirm={onDelete}
              >
                <p>
                  <strong>{title}</strong>
                </p>
                {content}
              </ConfirmationModal>
            )}
            {isInEditMode && !!onClose && (
              <IconButtons.Close onClick={onClose} />
            )}
          </div>
        )}
      </div>

      {isInViewMode && content}
      {isInEditMode && taskItemType !== 'rate' && (
        <TaskEdit
          id={id}
          title={title}
          label={label}
          description={description}
          user={user}
          checkIn={checkIn}
          checkOut={checkOut}
          arrivalTime={arrivalTime}
          notes={notes}
          roomId={roomId}
          date={date}
          price={price}
          guestFirstName={user.firstName}
          guestLastName={user.lastName}
          guestEmail={guestEmail}
          guestPhone={guestPhone}
          onSubmit={onSubmit}
          onSuccess={onSuccess}
        />
      )}
      {isInEditMode && taskItemType === 'rate' && (
        <TaskEdit
          id={id}
          title={title}
          label={label}
          description={description}
          user={user}
          rateDate={date}
          rate={rate}
          minLOS={minLOS}
          allowMinLOSModification={allowMinLOSModification}
          allowRateModification={allowRateModification}
          onSubmit={onSubmit}
          onSuccess={onSuccess}
        />
      )}
    </div>
  );
};

export default React.memo(TaskItem);
