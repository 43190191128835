import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Image } from 'semantic-ui-react';
import ImageButton from './Buttons/ImageButton';
import EllipsisText from './EllipsisText';
import Colors from '../utils/colors';
import DateUtils from '../utils/dateUtils';
import Breakpoints from '../utils/breakpoints';
import { authSelector } from '../selectors';

const flexInline = {
  display: 'flex',
  alignItems: 'center'
};

const spaced = {
  '& > *:not(:last-child)': {
    marginRight: 10
  }
};

const useStyles = createUseStyles(
  {
    root: {
      extend: flexInline,
      padding: '1rem',
      '&:not(:last-child)': {
        borderBottom: `1px solid ${Colors.grayTwo}`
      }
    },
    title: {
      fontSize: 12,
      flexBasis: '25%'
    },
    description: {
      flexBasis: '45%',
      margin: '0 10px'
    },
    time: {
      margin: '0 10px',
      extend: [flexInline, spaced],
      color: Colors.grayFour
    },
    actions: {
      extend: [flexInline, spaced],
      marginLeft: 'auto'
    },
    [`@media (max-width: ${Breakpoints.lg}px)`]: {
      root: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& > *:not(:last-child)': {
          marginBottom: 10
        }
      },
      title: {
        flexBasis: 'unset'
      },
      description: {
        flexBasis: '100%',
        margin: 0
      },
      time: {
        margin: 0
      },
      actions: {
        width: '100%',
        marginTop: '1rem',
        '& > *': {
          flexGrow: 1
        }
      }
    }
  },
  { name: 'Document' }
);

const Document = ({ id, title, publishedDate, description }) => {
  const classes = useStyles();
  const { token } = authSelector();
  const [loading, setLoading] = useState(false);

  const downloadDocument = async () => {
    setLoading(true);

    try {
      const response = await fetch(`/api/downloadDocument?documentId=${id}`, {
        method: 'GET',
        headers: { 'Ownerportal-Token': token }
      });
      const blob = await response.blob();
      const filename = response.headers.get('Content-disposition');
      const name = filename.split('filename = ')[1];
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.download = name || title;
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <EllipsisText className={classes.title}>
        <strong>{title}</strong>
      </EllipsisText>
      <div className={classes.description}>{description}</div>
      <div className={classes.time}>
        {publishedDate != null && <Image src="/assets/time.svg" />}
        <time>
          {publishedDate != null
            ? DateUtils.formatDate(publishedDate, 'MM-DD-YYYY')
            : ''}
        </time>
      </div>
      <div className={classes.actions}>
        <ImageButton
          src="/assets/view.svg"
          text="View"
          loading={loading}
          disabled={loading}
          onClick={downloadDocument}
        />
      </div>
    </div>
  );
};

export default React.memo(Document);
