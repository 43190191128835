import ACTIONS from '../actions/ownerActions';

const initialState = {
  roomInfoList: [],
  propertyList: [],
  selectedRoomId: null
};

const ownerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.RESET_APP: {
      return initialState;
    }
    case ACTIONS.PREPARE_APP: {
      const { roomInfoList: rooms = [], propertyList = [] } = action.payload;
      const roomInfoList = rooms.map(room => ({
        ...room,
        property: propertyList.find(({ id }) => id === room.propertyId)
      }));

      return {
        ...state,
        propertyList,
        roomInfoList,
        selectedRoomId: roomInfoList[0]?.id
      };
    }
    case ACTIONS.SELECT_ROOM_ID: {
      const selectedRoomId = action.payload;
      return { ...state, selectedRoomId };
    }
    default: {
      return state;
    }
  }
};

export default ownerReducer;
