import React from 'react';
import { createUseStyles } from 'react-jss';
import useOwnerProtection from '../../hooks/useOwnerProtection';
import Colors from '../../utils/colors';

const useStyles = createUseStyles(
  {
    root: {
      flex: 1,
      padding: '2rem',
      borderRight: `1px solid ${Colors.grayTwo}`
    }
  },
  { name: 'OwnerDashboardView' }
);

const OwnerDashboardView = () => {
  useOwnerProtection();

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h1>Dashboard</h1>
    </div>
  );
};

export default React.memo(OwnerDashboardView);
