import React from 'react';
import { List } from 'semantic-ui-react';
import { createUseStyles } from 'react-jss';
import useAxios from 'axios-hooks';
import ImageButton from './Buttons/ImageButton';
import DateUtils from '../utils/dateUtils';
import Colors from '../utils/colors';
import Breakpoints from '../utils/breakpoints';

/* FIXME: Hardcoding the URL due to prod build issues
const OWNER_PORTAL_HOST = process.env.REACT_APP_OWNER_PORTAL_HOST; */

const useStyles = createUseStyles(
  {
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '1.5rem'
    },
    dates: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'baseline',
      justifyContent: 'space-between',
      '&.ui.list': {
        margin: 0
      }
    },
    description: {
      fontWeight: 'bold'
    },
    endDate: {
      fontWeight: 'normal',
      color: Colors.grayFour
    },
    [`@media (max-width: ${Breakpoints.lg}px)`]: {
      root: {
        flexDirection: 'column',
        alignItems: 'unset'
      },
      dates: {
        flexDirection: 'row',
        '&.ui.list': {
          margin: '0 0 1.5rem 0'
        }
      }
    }
  },
  { name: 'Statement' }
);

const Statement = ({ id, endDate, description }) => {
  const classes = useStyles();

  const [{ loading }, execute] = useAxios(
    {
      url: '/api/createOwnerStatementAccesToken',
      method: 'POST'
    },
    { manual: true }
  );

  const onClick = () => {
    execute({ data: { ownerStatementId: id } })
      .then(({ data: token }) => {
        window.open(
          `https://app.wavepm.com/ownerStatementReportPage?token=${token}`,
          '_blank'
        );
      })
      .catch(error => {
        console.error(error);
      });
  };

  return (
    <div className={classes.root}>
      <List className={classes.dates}>
        <List.Item
          className={classes.endDate}
          content={DateUtils.formatDate(endDate)}
        />
        <List.Item
          className={classes.description}
          content={description || 'N/A'}
        />
      </List>
      <ImageButton
        src="/assets/printer.svg"
        text="Generate Statement"
        onClick={onClick}
        loading={loading}
        disabled={loading}
      />
    </div>
  );
};

export default React.memo(Statement);
