import React from 'react';
import TaskItem from './TaskItem';

const MaintenanceTask = ({ title, label, description, date, price }) => {
  const onSubmit = data => {
    const promise = new Promise(resolve => {
      setTimeout(() => {
        resolve(data);
      }, 2000);
    });

    return promise;
  };

  return (
    <TaskItem
      title={title}
      label={label}
      description={description}
      date={date}
      price={price}
      onSubmit={onSubmit}
      readOnly
      taskItemType="maintenance"
    />
  );
};

export default React.memo(MaintenanceTask);
