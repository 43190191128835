import React, { useState } from 'react';
import { Button, Form, Input, TextArea, Message } from 'semantic-ui-react';
import Joi from 'joi';
import validateSchema from '../../utils/validateSchema';
import DateUtils from '../../utils/dateUtils';
import DatepickerField from '../Fields/DatepickerField';
import TimepickerField from '../Fields/TimepickerField';
import RoomSelectField from '../Fields/RoomSelectField';
import ReadOnlyDateField from '../Fields/ReadOnlyDateField';

const formatDate = date => DateUtils.toDate(date);
const formatDate2 = date => DateUtils.formatDate(date);
const decodeTime = time => DateUtils.decodeTime(time);

const dataSchema = Joi.object({
  description: Joi.string().allow(null),
  checkIn: Joi.date().allow(null),
  checkOut: Joi.date().greater(Joi.ref('checkIn')).allow(null),
  arrivalTime: Joi.string().allow(null, ''),
  notes: Joi.string().allow(null, ''),
  roomId: Joi.number().allow(null),
  date: Joi.date().allow(null),
  price: Joi.number().positive().integer().allow(null),
  guestFirstName: Joi.string().allow(null),
  guestLastName: Joi.string().allow(null),
  guestEmail: Joi.string().allow(null),
  guestPhone: Joi.string().allow(null),
  minLOS: Joi.number().min(0).integer().allow(null).label('Min Length of Stay'),
  rate: Joi.number().positive().integer().allow(null)
});

const config = {
  description: { type: 'text' },
  checkIn: {
    control: null,
    component: DatepickerField,
    format: formatDate
  },
  checkOut: {
    control: null,
    component: DatepickerField,
    format: formatDate,
    props: ({ checkIn }) => ({
      min: DateUtils.defaultFormat(DateUtils.addDay(checkIn))
    })
  },
  arrivalTime: {
    control: null,
    component: TimepickerField,
    format: decodeTime
  },
  notes: {
    type: 'text',
    control: TextArea,
    props: () => ({ rows: 5 })
  },
  roomId: {
    type: 'text',
    component: RoomSelectField,
    needs: ['checkIn', 'checkOut']
  },
  date: {
    control: null,
    component: DatepickerField,
    format: formatDate
  },
  rateDate: {
    control: null,
    component: ReadOnlyDateField,
    format: formatDate2,
    label: 'Date'
  },
  price: {
    type: 'number'
  },
  minLOS: {
    type: 'number',
    label: 'Min Length of Stay'
  },
  rate: {
    type: 'number',
    label: 'Rate',
    icon: 'dollar',
    iconPosition: 'left'
  }
};

const TaskEdit = ({ onSubmit, onSuccess, ...rest }) => {
  const [data, setData] = useState(rest || {});
  const [state, setState] = useState({
    failed: false,
    loading: false,
    errors: {}
  });
  const { failed, loading, errors } = state;

  const onError = error => {
    console.error(error);
    setState({ ...state, failed: true });
  };

  const onSubmitForm = () => {
    validateSchema(dataSchema, data)
      .then(() => {
        setState({ loading: true, failed: false, errors: {} });
        onSubmit(data).then(onSuccess).catch(onError);
      })
      .catch(schemaErrors => {
        setState({ loading: false, failed: true, errors: schemaErrors });
      });
  };

  const onChange = (_event, { id, value }) => {
    setState({ ...state, errors: { ...errors, [id]: null }, failed: false });
    setData({ ...data, [id]: value });
  };

  const camelCaseToHuman = key => {
    const upperCased = key.charAt(0).toUpperCase() + key.slice(1);
    return upperCased.match(/[A-Z][a-z]+/g).join(' ');
  };

  const { allowMinLOSModification, allowRateModification } = data;

  return (
    <Form loading={loading} onSubmit={onSubmitForm}>
      {Object.keys(data).map(key => {
        const field = config[key];
        let value = data[key];

        if (!field || value === undefined) {
          return null;
        }

        if ((key === 'minLOS' || key === 'rate') && value == null) {
          return null;
        }

        const {
          type,
          format,
          readOnly = (key === 'rate' && !allowRateModification) ||
            (key === 'minLOS' && !allowMinLOSModification),
          control = Input,
          props = () => ({}),
          component: Component = Form.Field,
          needs,
          label,
          icon,
          iconPosition
        } = field;
        value = format ? format(value) : value;

        let attrs = {
          id: key,
          control,
          label: !label ? camelCaseToHuman(key) : label,
          placeholder: key,
          type,
          value,
          onChange,
          error: errors[key],
          readOnly,
          icon,
          iconPosition,
          ...props(data)
        };

        if (needs) {
          const extra = needs.reduce((obj, item) => {
            obj[item] = data[item];
            return obj;
          }, {});

          attrs = { ...attrs, ...extra };
        }

        return <Component key={key} {...attrs} />;
      })}
      <Message
        header="Something Went Wrong"
        content="Please, try again later"
        visible={failed}
        error
      />
      <Button size="mini" type="submit" primary>
        Submit
      </Button>
    </Form>
  );
};

export default React.memo(TaskEdit);
