import React from 'react';
import { Dropdown, Image } from 'semantic-ui-react';
import { createUseStyles } from 'react-jss';
import { ownerSelector } from '../../selectors';
import Selects from '../../utils/selects';

const useStyles = createUseStyles(
  {
    root: {
      '& .ui.image': {
        height: 16,
        marginTop: 'auto !important'
      }
    }
  },
  { name: 'UnitsDropdown' }
);

const UnitsDropdown = ({ onChange, value }) => {
  const classes = useStyles();
  const { roomInfoList } = ownerSelector();

  const UNITS = roomInfoList.map(room => ({
    ...Selects.getRoomAsOption(room),
    image: { src: '/assets/marker.svg', avatar: true }
  }));

  return (
    <Dropdown
      className={classes.root}
      options={UNITS}
      placeholder={
        <span>
          <Image src="/assets/marker.svg" />
          All Units
        </span>
      }
      selection
      onChange={onChange}
      value={value}
    />
  );
};

export default React.memo(UnitsDropdown);
