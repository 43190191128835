import React, { useState } from 'react';
import { Button, Form, Input, Message } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import useAxios from 'axios-hooks';
import Joi from 'joi';
import AUTH_ACTIONS from '../actions/authAction';
import validateSchema from '../utils/validateSchema';
import { RESPONSE_STATUS } from '../utils/constants';
import { userSelector } from '../selectors';
// import { userSelector } from '../selectors';

const profileSchema = Joi.object({
  password: Joi.string()
    .min(8)
    .max(25)
    .regex(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'password'
    )
    .required()
    .messages({ 'any.only': 'password does not match with validations' }),
  confirmPassword: Joi.any()
    .equal(Joi.ref('password'))
    .required()
    .messages({ 'any.only': 'password does not match' })
});

const ChangePasswordForm = () => {
  // const user = userSelector();
  const dispatch = useDispatch();
  const user = userSelector();
  const [message, setMessage] = useState(null);
  const [errors, setErrors] = useState({
    password: null,
    confirmPassword: null
  });

  const [profile, setProfile] = useState({
    password: '',
    confirmPassword: ''
  });

  const [{ loading }, execute] = useAxios(
    {
      url: '/api/changePassword',
      method: 'POST'
    },
    { manual: true }
  );

  const onError = () => {
    setMessage({
      header: 'Something Went Wrong',
      content: 'Please, try again later',
      error: true
    });
  };

  const onSuccess = ({ status }) => {
    if (status === RESPONSE_STATUS.OK) {
      setMessage({
        header: 'Success',
        content: 'Your password has been updated',
        success: true
      });
      setErrors({
        password: null,
        confirmPassword: null
      });
      setProfile({
        password: '',
        confirmPassword: ''
      });
      dispatch({
        type: AUTH_ACTIONS.UPDATE_USER,
        payload: { changePassword: false }
      });
    }
  };

  const onSubmit = () => {
    validateSchema(profileSchema, profile)
      .then(() => {
        execute({
          data: {
            newPassword: profile.password
          }
        })
          .then(onSuccess)
          .catch(onError);
      })
      .catch(setErrors);
  };

  const { password, confirmPassword } = profile;

  const onChange = (_event, { id, value }) => {
    setErrors({ ...errors, [id]: null });
    setProfile({ ...profile, [id]: value.trim() });
  };

  return (
    <Form loading={loading} onSubmit={onSubmit}>
      {!!message && <Message {...message} visible />}
      {/* <Form.Group widths="equal">
        <Form.Field
          id="firstName"
          control={Input}
          label="First Name"
          type="text"
          value={user?.firstName}
          readOnly
        />
        <Form.Field
          id="lastName"
          control={Input}
          label="Last Name"
          type="text"
          value={user?.lastName}
          readOnly
        />
        <Form.Field
          id="email"
          control={Input}
          label="Email"
          type="text"
          value={user?.email}
          readOnly
        />
  </Form.Group> */}
      {!message && !user?.changePassword && (
        <Message
          header="Change Password"
          content="New password should be at least 8 characters long and contain at least the following: 1 or more capital letters, 1 or more lowercase letters, 1 or more special characters (@#$%^&+=&#33;,), 1 or more base numbers (0-9)"
          warning
          visible
        />
      )}
      <Form.Group widths="equal">
        <Form.Field
          id="password"
          control={Input}
          label="Password"
          placeholder="Password"
          type="password"
          value={password}
          onChange={onChange}
          error={errors.password}
        />
        <Form.Field
          id="confirmPassword"
          control={Input}
          label="Confirm Password"
          placeholder="Confirm Password"
          type="password"
          value={confirmPassword}
          onChange={onChange}
          error={errors.confirmPassword}
        />
      </Form.Group>
      <Button type="submit">Change password</Button>
    </Form>
  );
};

export default React.memo(ChangePasswordForm);
