import React, { useState } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { createUseStyles } from 'react-jss';
import usePrepareCalendar from '../../hooks/usePrepareCalendar';
import useWeather from '../../hooks/useWeather';
import useOwnerProtection from '../../hooks/useOwnerProtection';
import { ownerSelector } from '../../selectors';
import Calendar from '../../components/Calendar/Calendar';
import ReservationPanel from '../../components/ReservationPanel';
import CreateReservationModal from '../../components/ReservationModal/CreateReservationModal';
import Colors from '../../utils/colors';
import Breakpoints from '../../utils/breakpoints';

const useStyles = createUseStyles(
  {
    root: {
      display: 'flex',
      flex: 1
    },
    content: {
      display: 'flex',
      maxWidth: '100vw',
      flexDirection: 'column',
      flex: 1,
      padding: '2rem',
      borderRight: `1px solid ${Colors.grayTwo}`
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '2rem'
    },
    title: {
      color: Colors.blackOne,
      margin: 0
    },
    [`@media (max-width: ${Breakpoints.lg}px)`]: {
      root: {
        flexDirection: 'column'
      }
    }
  },
  { name: 'OwnerCalendarView' }
);

const OwnerCalendarView = () => {
  useOwnerProtection();

  const classes = useStyles();
  const [defaultDate, setDefaultDate] = useState(new Date());
  const [{ loading, year, events }] = usePrepareCalendar(defaultDate);
  const { propertyList } = ownerSelector();
  const [{ data: weather }] = useWeather(propertyList[0]);

  const onSuccess = date => {
    setDefaultDate(new Date(date));
  };

  return (
    <Dimmer.Dimmable dimmed={loading} className={classes.root}>
      <Dimmer active={loading} inverted>
        <Loader inverted content="Loading calendar..." />
      </Dimmer>
      <div className={classes.content}>
        <div className={classes.header}>
          <h1 className={classes.title}>Calendar</h1>
          <CreateReservationModal onCreate={onSuccess} />
        </div>
        <Calendar
          year={year}
          events={events}
          onChange={setDefaultDate}
          defaultDate={defaultDate}
        />
      </div>
      <ReservationPanel
        weather={weather}
        onUpdate={onSuccess}
        onDelete={onSuccess}
        onExclude={onSuccess}
      />
    </Dimmer.Dimmable>
  );
};

export default React.memo(OwnerCalendarView);
