import React from 'react';
import { Link } from 'react-router-dom';
import BasicLayout from '../layouts/BasicLayout';

const UnauthorizedView = () => (
  <BasicLayout>
    <h1>403 - You Shall Not Pass</h1>
    <Link to="/sign-out">Back to Login</Link>
  </BasicLayout>
);

export default UnauthorizedView;
