import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import EVENTS_ACTIONS from '../actions/eventsActions';
import OWNER_ACTIONS from '../actions/ownerActions';
import AUTH_ACTIONS from '../actions/authAction';
import { userSelector } from '../selectors';

const cleanStore = () => dispatch =>
  Promise.all([
    dispatch({ type: EVENTS_ACTIONS.RESET_SELECTED }),
    dispatch({ type: OWNER_ACTIONS.RESET_APP })
  ]);

const SignOutView = () => {
  const dispatch = useDispatch();
  const user = userSelector();

  const actions = bindActionCreators({ cleanStore }, dispatch);
  const signOut = () => dispatch({ type: AUTH_ACTIONS.SIGN_OUT });

  useEffect(() => {
    setTimeout(() => {
      actions.cleanStore().then(signOut);
    }, 1500);
  }, []);

  if (!user) {
    return <Redirect to="/" />;
  }

  return (
    <Loader id="full-screen-loader" size="big" indeterminate>
      Signing out...
    </Loader>
  );
};

export default React.memo(SignOutView);
