import React from 'react';
import { Image } from 'semantic-ui-react';
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';
import Colors from '../../utils/colors';
import IconButtons from '../Buttons/IconButtons';

const bordered = {
  border: `1px solid ${Colors.grayTwo}`,
  borderRadius: '4px'
};

const useStyles = createUseStyles(
  {
    header: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1rem'
    },
    title: {
      margin: 0
    },
    actions: {
      marginLeft: 'auto'
    },
    content: {
      '& > *': {
        padding: '1.5rem',
        borderBottom: `1px solid ${Colors.grayTwo}`,
        '&:last-child': {
          borderBottom: 'none'
        }
      }
    },
    spaced: {
      border: 'none',
      '& > *': {
        extend: bordered,
        '&:not(:last-child)': {
          marginBottom: '1rem'
        },
        '&:last-child': {
          borderBottom: `1px solid ${Colors.grayTwo}`
        }
      }
    }
  },
  { name: 'TaskGroup' }
);

const TaskGroup = ({
  title,
  image,
  spaced,
  actionable,
  children,
  show = true
}) => {
  const classes = useStyles();

  return (
    show && (
      <div>
        <div className={classes.header}>
          {!!image && <Image src={image} style={{ marginRight: '0.5rem' }} />}
          <h4 className={classes.title}>{title}</h4>
          {actionable && (
            <div className={classes.actions}>
              <IconButtons.Add />
            </div>
          )}
        </div>
        <div
          className={classnames(classes.content, { [classes.spaced]: spaced })}
        >
          {children}
        </div>
      </div>
    )
  );
};

export default React.memo(TaskGroup);
