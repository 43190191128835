import React from 'react';
import { createUseStyles } from 'react-jss';
import { Image } from 'semantic-ui-react';
import Colors from '../utils/colors';
import Breakpoints from '../utils/breakpoints';

const useStyles = createUseStyles(
  {
    root: {
      border: `1px solid ${Colors.grayTwo}`,
      borderRadius: 4,
      marginBottom: '2rem'
    },
    header: separator => ({
      display: 'flex',
      alignItems: 'center',
      padding: '1.5rem 1.5rem',
      borderBottom: separator ? `1px solid ${Colors.grayTwo}` : 'unset'
    }),
    title: {
      fontWeight: 'normal',
      lineHeight: 'unset',
      margin: 0
    },
    subtitle: {
      fontSize: '0.65em',
      lineHeight: 'unset',
      color: Colors.grayFour
    },
    actions: {
      flex: 1,
      textAlign: 'right'
    },
    image: {
      display: 'inline !important',
      marginRight: '1.5rem'
    },
    [`@media (max-width: ${Breakpoints.md}px)`]: {
      header: {
        flexDirection: 'column',
        alignItems: 'unset !important'
      },
      actions: {
        marginTop: '1rem',
        textAlign: 'unset !important',
        '& > *': {
          width: '100%'
        }
      }
    }
  },
  { name: 'Box' }
);

const Box = ({
  title,
  subtitle,
  image,
  actions,
  separator = true,
  children
}) => {
  const classes = useStyles(separator);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <h3 className={classes.title}>
          {!!image && <Image className={classes.image} src={image} />}
          {title}
          {!!subtitle && (
            <small className={classes.subtitle}>
              <br />
              {subtitle}
            </small>
          )}
        </h3>
        {!!actions && <div className={classes.actions}>{actions}</div>}
      </div>
      {children}
    </div>
  );
};

export default React.memo(Box);
