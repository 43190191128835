import { useSelector } from 'react-redux';

export const authSelector = () => useSelector(state => state.auth);
export const userSelector = () => useSelector(state => state.auth.user);
export const ownerSelector = () => useSelector(state => state.owner);
export const eventsSelector = () => useSelector(state => state.events);

const selectors = {
  authSelector,
  userSelector,
  ownerSelector,
  eventsSelector
};

export default selectors;
