import React from 'react';
import { createUseStyles } from 'react-jss';
import { Message } from 'semantic-ui-react';
import Colors from '../../utils/colors';
import useOwnerProtection from '../../hooks/useOwnerProtection';
import { userSelector } from '../../selectors';
import ChangePasswordForm from '../../components/ChangePasswordForm';
import ProfileForm from '../../components/ProfileForm';

const useStyles = createUseStyles(
  {
    root: {
      flex: 1,
      padding: '2rem',
      borderRight: `1px solid ${Colors.grayTwo}`
    },
    profile: {
      marginTop: '50px',
      marginBottom: '50px'
    }
  },
  { name: 'OwnerProfileView' }
);

const OwnerProfileView = () => {
  useOwnerProtection();

  const user = userSelector();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h1>Profile</h1>
      {user?.changePassword && (
        <Message
          header="Temporary password"
          content="You are using a temporary password, please change it. New password should be at least 8 characters long and contain at least the following: 1 or more capital letters, 1 or more lowercase letters, 1 or more special characters (@#$%^&+=&#33;,), 1 or more base numbers (0-9)"
          warning
        />
      )}
      <ProfileForm className={classes.profile} />
      <ChangePasswordForm />
    </div>
  );
};

export default React.memo(OwnerProfileView);
