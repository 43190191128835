import React from 'react';
import { Image } from 'semantic-ui-react';
import { createUseStyles } from 'react-jss';
import MenuItem from './MenuItem';
import Colors from '../../utils/colors';
import Breakpoints from '../../utils/breakpoints';

const useStyles = createUseStyles(
  {
    root: {
      height: '100%',
      padding: '3em 1rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: Colors.whiteTwo,
      color: Colors.grayOne
    },
    image: {
      width: '30px',
      marginBottom: '80px'
    },
    [`@media (max-width: ${Breakpoints.lg}px)`]: {
      image: {
        marginBottom: '2rem'
      }
    }
  },
  { name: 'OwnerMenu' }
);

const OwnerMenu = ({ onClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Image src="/assets/wave.svg" className={classes.image} />
      <MenuItem
        image="/assets/calendar.svg"
        text="Calendar"
        link="/owner/calendar"
        onClick={onClick}
      />
      <MenuItem
        image="/assets/statement.svg"
        text="Statements"
        link="/owner/statements"
        onClick={onClick}
      />
      <MenuItem
        image="/assets/document.svg"
        text="Documents"
        link="/owner/documents"
        onClick={onClick}
      />
      {/* <MenuItem
        image="/assets/key_code.svg"
        text="Key Codes"
        link="/owner/key-codes"
        onClick={onClick}
      /> */}
      <MenuItem
        image="/assets/guest_survey.svg"
        text="Guest Surveys"
        link="/owner/guest-surveys"
        onClick={onClick}
      />
      <MenuItem
        image="/assets/bell.png"
        text="Support"
        link="/owner/support"
        onClick={onClick}
        filter="grayscale(1)"
      />
    </div>
  );
};

export default React.memo(OwnerMenu);
