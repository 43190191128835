import React from 'react';
import { Form } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { createUseStyles } from 'react-jss';
import { EN_US, FORMAT } from '../../utils/dateUtils';

const useStyles = createUseStyles(
  {
    root: {
      '& .field': {
        width: '100%'
      }
    }
  },
  { name: 'DatepickerField' }
);

const DatepickerField = ({ id, label, ...props }) => {
  const classes = useStyles();

  return (
    <Form.Field className={classes.root}>
      <label htmlFor={id}>{label}</label>
      <SemanticDatepicker
        {...props}
        locale={EN_US.code}
        format={FORMAT}
        id={id}
        type="basic"
        icon="calendar outline"
        iconPosition="right"
        clearable={props.clearable ? props.clearable : false}
        datePickerOnly
      />
    </Form.Field>
  );
};

export default React.memo(DatepickerField);
