import React from 'react';
import { createUseStyles } from 'react-jss';
import { sortBy } from 'lodash';
import Tab from './Tab';
import Review from './Review';
import moment from '../utils/moment';
import Colors from '../utils/colors';
import Breakpoints from '../utils/breakpoints';
import DateUtils from '../utils/dateUtils';

const useStyles = createUseStyles(
  {
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > .review': {
        borderBottom: `1px solid ${Colors.grayTwo}`,
        flexBasis: '100%',
        '&:last-child': {
          borderBottom: 'none'
        },
        [`@media (min-width: ${Breakpoints.lg}px)`]: {
          flexBasis: '50%',
          '&:nth-child(even)': {
            borderLeft: `1px solid ${Colors.grayTwo}`
          },
          '&:nth-last-child(-n+2)': {
            borderBottom: 'none'
          }
        }
      }
    }
  },
  { name: 'IndividualUnitReviewsTab' }
);

const IndividualUnitReviewsTab = ({ reviews }) => {
  const classes = useStyles();

  const dateToTitle = (checkIn, checkOut) => {
    const nights = DateUtils.getNumberOfNights(checkIn, checkOut);
    const nigthLabel = nights > 1 ? 'Nights' : 'Night';
    return `${DateUtils.formatDate(checkIn)} - ${nights} ${nigthLabel}`;
  };

  const items = [
    {
      title: 'Recent',
      filter: ({ checkIn }) =>
        moment(checkIn).format('YYYY') !== moment().year(),
      sortedBy: ['checkIn'],
      sortOrder: 'desc'
    },
    {
      title: 'Highest Rated',
      filter: () => true,
      sortedBy: ['overallRating', 'checkIn'],
      sortOrder: 'desc'
    },
    {
      title: 'Lowest Rated',
      filter: () => true,
      sortedBy: ['overallRating', 'checkIn'],
      sortOrder: 'asc'
    }
  ].map(({ title, filter, sortedBy, sortOrder }) => {
    const sortedReviews = sortBy(reviews.filter(filter), sortedBy);
    if (sortOrder === 'desc') {
      sortedReviews.reverse();
    }
    return {
      title,
      content: (
        <div className={classes.root}>
          {sortedReviews.map(
            ({ key, user, questions, checkIn, checkOut, comments }) => (
              <Review
                key={key}
                className="review"
                user={user}
                questions={questions}
                date={dateToTitle(checkIn, checkOut)}
                comments={comments}
              />
            )
          )}
        </div>
      )
    };
  });

  return <Tab items={items} />;
};

export default React.memo(IndividualUnitReviewsTab);
