import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';
import { round, sum, sumBy } from 'lodash';
import Rating from './Rating';
import { MAX_RATING, RATINGS } from '../utils/constants';
import Colors from '../utils/colors';
import StringUtils from '../utils/stringUtils';

const useStyles = createUseStyles(
  {
    root: {
      padding: '1.5rem'
    },
    text: {
      color: Colors.grayFour,
      marginBottom: '1rem'
    },
    score: {
      display: 'inline-flex',
      alignItems: 'center',
      marginBottom: '1rem'
    },
    number: {
      fontSize: 20,
      fontWeight: 'bold',
      marginLeft: '1rem'
    },
    ratings: {
      fontSize: 12,
      fontWeight: 500,
      color: 'black'
    },
    bar: {
      display: 'inline-flex',
      height: 5,
      width: '100%',
      backgroundColor: Colors.whiteTwo,
      borderRadius: 4,
      marginBottom: '0.5rem'
    },
    progress: {
      height: '100%',
      width: '0%',
      transition: 'width 1s linear',
      '&:first-child': {
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4
      },
      '&:last-child': {
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4
      },
      '&:not(:last-child)': {
        borderRight: `2px solid white`
      }
    }
  },
  { name: 'QuestionInsight' }
);

const QuestionInsight = ({ className, text, ratings }) => {
  const [ready, setReady] = useState(false);
  const classes = useStyles();

  const total = sum(Object.values(ratings));
  const keys = Object.keys(ratings).sort().reverse();

  const stars = sumBy(keys, key => key * ratings[key]);
  const score = stars / total;

  setTimeout(() => setReady(true), 500);

  return (
    <div className={classnames(classes.root, className)}>
      <p className={classes.text}>{text}</p>
      <div className={classes.score}>
        <Rating size="huge" score={round(score)} />
        <span className={classes.number}>
          {round(score, 1)} of {MAX_RATING} Stars
        </span>
      </div>
      <div className={classes.bar}>
        {keys.map(key => {
          const value = ratings[key];
          return (
            !!value && (
              <div
                key={key}
                className={classes.progress}
                style={
                  ready
                    ? {
                        backgroundColor: RATINGS[key].color,
                        width: `${(value * 100) / total}%`
                      }
                    : {}
                }
              />
            )
          );
        })}
      </div>
      <small className={classes.ratings}>
        {StringUtils.numberToText(total)} ratings
      </small>
    </div>
  );
};

export default React.memo(QuestionInsight);
