import { combineReducers } from 'redux';
import authReducer from './authReducer';
import ownerReducer from './ownerReducer';
import eventsReducer from './eventsReducer';

const reducers = {
  auth: authReducer,
  owner: ownerReducer,
  events: eventsReducer
};

const rootReducer = combineReducers(reducers);
export default rootReducer;
