import React from 'react';
import { Image } from 'semantic-ui-react';
import { createUseStyles } from 'react-jss';
import { round } from 'lodash';
import Colors from '../utils/colors';

const useStyles = createUseStyles(
  {
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '1rem',
      borderRadius: '4px',
      height: '121px',
      backgroundColor: Colors.blueOne
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'space-between',
      color: Colors.whiteOne
    },
    maxTemp: {
      fontSize: '37px',
      lineHeight: '43px'
    },
    lowTemp: {
      fontSize: '22px',
      lineHeight: '26px'
    },
    scale: {
      fontSize: '38px',
      lineHeight: '45px',
      opacity: '0.6'
    }
  },
  { name: 'Weather' }
);

const placeholder = {
  title: 'Today',
  maxTemp: 'N/A',
  minTemp: 'N/A',
  icon: 'sunny.svg',
  description: 'Sunny'
};

const Weather = (props = placeholder) => {
  const classes = useStyles();
  const { title, maxTemp, minTemp, icon, description, scale } = props;

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <span>
          <b>{title}</b>
        </span>
        <div className={classes.temps}>
          <span className={classes.maxTemp}>{round(maxTemp)} </span>
          <span className={classes.lowTemp}>/ {round(minTemp)} </span>
          <span className={classes.scale}>{scale}</span>
        </div>
        <span>
          <small>{description}</small>
        </span>
      </div>
      <Image src={`/assets/${icon}`} size="tiny" />
    </div>
  );
};

export default React.memo(Weather);
