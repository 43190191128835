import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Form, Input, Message } from 'semantic-ui-react';
import useAxios from 'axios-hooks';
import Joi from 'joi';
import Colors from '../utils/colors';
import BasicLayout from '../layouts/BasicLayout';
import AUTH_ACTIONS from '../actions/authAction';
import { userSelector } from '../selectors';
import validateSchema from '../utils/validateSchema';
import { OWNER_ROUTES } from '../utils/constants';
import ForgotPassword from '../components/ForgotPassword';

const useStyles = createUseStyles(
  {
    logo: {
      backgroundImage: "url('/assets/dark_logo.png')",
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '50%',
      height: 70,
      marginBottom: '2rem'
    },
    submit: {
      width: '100%',
      color: `${Colors.whiteOne} !important`,
      background: `${Colors.greenOne} !important`
    },
    forgotPassword: {
      display: 'block',
      cursor: 'pointer',
      textAlign: 'center',
      margin: '1rem 0'
    }
  },
  { name: 'LoginView' }
);

const loginSchema = Joi.object({
  username: Joi.string().min(6).max(320),
  password: Joi.string().min(3).max(255)
});

const LoginView = () => {
  const classes = useStyles();
  const user = userSelector();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({ username: null, password: null });
  const [failed, setFailed] = useState(false);
  const [credentials, setCredentials] = useState({
    username: '',
    password: ''
  });

  const [{ loading }, execute] = useAxios(
    {
      url: '/api/login',
      method: 'POST'
    },
    { manual: true }
  );

  const onSubmit = () => {
    validateSchema(loginSchema, credentials)
      .then(() => {
        execute({
          data: credentials
        })
          .then(({ data: { token, ...rest } }) => {
            dispatch({
              type: AUTH_ACTIONS.SIGN_IN,
              payload: { user: rest, token }
            });
          })
          .catch(setFailed);
      })
      .catch(setErrors);
  };

  const { username, password } = credentials;

  const onChange = (_event, { id, value }) => {
    setErrors({ ...errors, [id]: null });
    setCredentials({ ...credentials, [id]: value.trim() });
  };

  if (user) {
    return <Redirect to={OWNER_ROUTES.root} />;
  }

  return (
    <BasicLayout>
      <>
        <div className={classes.logo} />
        <Form loading={loading} onSubmit={onSubmit}>
          <Form.Field
            id="username"
            control={Input}
            placeholder="Username"
            type="text"
            value={username}
            onChange={onChange}
            error={errors.username}
          />
          <Form.Field
            id="password"
            control={Input}
            placeholder="Password"
            type="password"
            value={password}
            onChange={onChange}
            error={errors.password}
          />
          <Message
            header="Invalid Credentials"
            content="Please, enter a correct username and password."
            visible={!!failed}
            error
          />
          <Button className={classes.submit} type="submit">
            Submit
          </Button>
        </Form>
        <ForgotPassword
          trigger={
            <span className={classes.forgotPassword}>Forget password?</span>
          }
        />
      </>
    </BasicLayout>
  );
};

export default React.memo(LoginView);
