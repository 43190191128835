import React from 'react';
import { Form, Input } from 'semantic-ui-react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(
  {
    root: {
      "& input[type='time']": {
        minHeight: 38,
        position: 'relative',
        '&::-webkit-calendar-picker-indicator': {
          position: 'absolute',
          top: 0,
          right: 0,
          width: '100%',
          height: '100%',
          padding: 0,
          color: 'transparent',
          background: 'transparent'
        }
      }
    }
  },
  { name: 'TimepickerField' }
);

const TimepickerField = ({ id, label, ...props }) => {
  const classes = useStyles();

  return (
    <Form.Field className={classes.root}>
      <label htmlFor={id}>{label}</label>
      <Input {...props} id={id} type="time" icon="time" />
    </Form.Field>
  );
};

export default React.memo(TimepickerField);
