import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Image, Button, Form, TextArea, Message } from 'semantic-ui-react';
import useAxios from 'axios-hooks';
import Joi from 'joi';
import ModalForm from './ModalForm';
import Breakpoints from '../utils/breakpoints';
import Colors from '../utils/colors';
import validateSchema from '../utils/validateSchema';
import useModalState from '../hooks/useModalState';

const requestSchema = Joi.object({
  tileId: Joi.number().required(),
  roomId: Joi.number().required(),
  request: Joi.string().required()
});

const useStyles = createUseStyles(
  {
    root: {
      display: 'flex',
      alignItems: 'center',
      border: `1px solid ${Colors.grayTwo}`,
      borderRadius: 4,
      marginTop: '2rem',
      padding: '1.5rem'
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      width: '40%'
    },
    description: {
      margin: 0,
      wordBreak: 'keep-all',
      width: '40%',
      marginRight: 'auto'
    },
    title: {
      margin: 0
    },
    icon: {
      width: '40px',
      marginRight: '1rem'
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '20%'
    },
    [`@media (max-width: ${Breakpoints.lg}px)`]: {
      root: {
        flexDirection: 'column',
        padding: '2rem',
        '& > *': {
          width: '100%',
          '&:not(:last-child)': {
            marginBottom: '1rem'
          }
        }
      }
    }
  },
  { name: 'Request' }
);

const Request = ({
  tileId,
  roomId,
  title,
  description,
  iconUrl,
  action,
  buttonText = 'Submit'
}) => {
  const classes = useStyles();
  const { isOpen, onOpen, onClose } = useModalState();
  const [state, setState] = useState({
    data: { tileId, roomId, request: '' },
    errors: {},
    failed: false
  });

  const [{ loading }, execute] = useAxios(
    {
      url: '/api/sendRequestEmail',
      method: 'POST'
    },
    { manual: true }
  );

  const { data, errors, failed } = state;

  const onError = error => {
    console.error(error);
    setState({ ...state, failed: true });
  };

  const onSubmit = () => {
    validateSchema(requestSchema, data)
      .then(() => {
        execute({ data }).then(onClose).catch(onError);
      })
      .catch(schemaErrors => {
        setState({ ...state, errors: schemaErrors });
      });
  };

  const onChange = (_event, { id, value }) => {
    setState({
      ...state,
      data: { ...data, [id]: value },
      errors: { ...errors, [id]: null }
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Image src={iconUrl} className={classes.icon} />
        <h3 className={classes.title}>{title}</h3>
      </div>

      <p className={classes.description}>{description}</p>
      <ModalForm.Content
        open={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        trigger={
          !action && (
            <div className={classes.buttonContainer}>
              <Button
                className={classes.submit}
                content={buttonText}
                disabled={isOpen && loading}
                primary
                fluid
              />
            </div>
          )
        }
        title="Submit Request"
      >
        <Form loading={loading} onSubmit={onSubmit} onClose={onClose}>
          <ModalForm.Fields>
            <Form.Group widths="equal">
              <Form.Field
                id="request"
                control={TextArea}
                rows={5}
                label="Request"
                placeholder="Request"
                type="text"
                onChange={onChange}
                error={errors.request}
              />
              <Message
                header="Something Went Wrong"
                content="Please, try again later"
                visible={!!failed}
                error
              />
            </Form.Group>
          </ModalForm.Fields>
          <ModalForm.Actions>
            <Button color="red" onClick={onClose} disabled={loading}>
              Cancel
            </Button>
            <Button type="submit" disabled={loading} primary>
              Save
            </Button>
          </ModalForm.Actions>
        </Form>
      </ModalForm.Content>
      {!!action && (
        <div className={classes.buttonContainer}>
          <Button
            className={classes.submit}
            content={buttonText}
            onClick={() => window.open(action)}
            primary
            fluid
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(Request);
