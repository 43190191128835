import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useAxios from 'axios-hooks';
import { Loader } from 'semantic-ui-react';
import ProtectedRoute from './ProtectedRoute';
import { userSelector } from '../selectors';
import OwnerPanelLayout from '../layouts/OwnerPanelLayout';
import OwnerCalendarView from '../views/owner/OwnerCalendarView';
import OwnerDashboardView from '../views/owner/OwnerDashboardView';
import OwnerStatementsView from '../views/owner/OwnerStatementsView';
import OwnerDocumentsView from '../views/owner/OwnerDocumentsView';
// import OwnerKeyCodesView from '../views/owner/OwnerKeyCodesView';
import OwnerGuestSurveysView from '../views/owner/OwnerGuestSurveysView';
import OwnerSupportAndRequestsView from '../views/owner/OwnerSupportAndRequestsView';
import OwnerProfileView from '../views/owner/OwnerProfileView';
import ACTIONS from '../actions/ownerActions';

const OwnerPanelRoutes = () => {
  const user = userSelector();
  const dispatch = useDispatch();

  const [{ loading }, execute] = useAxios(
    {
      url: '/api/prepareApp',
      method: 'POST'
    },
    { manual: true }
  );

  useEffect(() => {
    execute({ data: {} }).then(response => {
      dispatch({
        type: ACTIONS.PREPARE_APP,
        payload: { ...response.data }
      });
    });
  }, []);

  if (loading) {
    return (
      <Loader id="full-screen-loader" size="big" indeterminate>
        Loading resources...
      </Loader>
    );
  }

  return (
    <OwnerPanelLayout>
      <Switch>
        <ProtectedRoute
          path="/owner"
          exact
          component={OwnerDashboardView}
          authorized={!!user}
        />
        <ProtectedRoute
          path="/owner/calendar"
          component={OwnerCalendarView}
          authorized={!!user}
        />
        <ProtectedRoute
          path="/owner/statements"
          component={OwnerStatementsView}
          authorized={!!user}
        />
        <ProtectedRoute
          path="/owner/documents"
          component={OwnerDocumentsView}
          authorized={!!user}
        />
        {/* <ProtectedRoute
          path="/owner/key-codes"
          component={OwnerKeyCodesView}
          authorized={!!user}
        /> */}
        <ProtectedRoute
          path="/owner/guest-surveys"
          component={OwnerGuestSurveysView}
          authorized={!!user}
        />
        <ProtectedRoute
          path="/owner/support"
          component={OwnerSupportAndRequestsView}
          authorized={!!user}
        />
        <ProtectedRoute
          path="/owner/profile"
          component={OwnerProfileView}
          authorized={!!user}
        />
      </Switch>
    </OwnerPanelLayout>
  );
};

export default React.memo(OwnerPanelRoutes);
