import React from 'react';
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';
import { round } from 'lodash';
import Rating from './Rating';
import UnitAverageScore from './UnitAverageScore';
import ProgressLine from './ProgressLine';
import { Media } from '../media';
import { MAX_RATING, RATINGS, EMPTY_RATING } from '../utils/constants';
import Breakpoints from '../utils/breakpoints';
import Colors from '../utils/colors';

const text = {
  display: 'block',
  margin: '0 0 0.5rem',
  fontSize: 12,
  fontWeight: 500,
  color: Colors.grayThree
};

const useStyles = createUseStyles(
  {
    root: {
      padding: '1.5rem'
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      color: Colors.grayThree
    },
    content: {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      padding: '1rem 0',
      '& > *:first-child': {
        flex: 1
      },
      '& > *:not(first-child)': {
        width: '100%'
      }
    },
    score: {
      display: 'inline-flex',
      alignItems: 'center',
      '& .rating ': {
        marginRight: '1rem !important'
      }
    },
    number: {
      fontSize: 14,
      fontWeight: 'bold'
    },
    questions: {
      '& > *:not(:last-child)': {
        marginBottom: '1rem'
      }
    },
    comments: {
      extend: text,
      marginTop: '1.9rem',
      height: '100%'
    },
    text: {
      extend: text
    },
    [`@media (max-width: ${Breakpoints.md}px)`]: {
      content: {
        flexDirection: 'column'
      }
    }
  },
  { name: 'Review' }
);

const Review = ({ className, user, date, questions, comments }) => {
  const classes = useStyles();
  const ratings = questions.reduce(
    (obj, { score }) => {
      obj[round(score)] += 1;
      return obj;
    },
    { ...EMPTY_RATING }
  );

  return (
    <div className={classnames(classes.root, className)}>
      <div className={classes.header}>
        <span>{date}</span>
        <span>{user}</span>
      </div>
      <div className={classes.content}>
        <UnitAverageScore
          ratings={ratings}
          legend="Overall Rating"
          includeBars={false}
        />
        <div className={classes.questions}>
          {questions.map(question => {
            const score = round(question.score);
            const progress = (score * 100) / MAX_RATING;
            const { color } = RATINGS[score];

            return (
              <div key={question.key}>
                <span className={classes.text}>{question.text}</span>
                <div className={classes.score}>
                  <Media greaterThanOrEqual="md">
                    <Rating size="large" score={score} />
                  </Media>
                  <span className={classes.number}>
                    {round(question.score, 1)} of {MAX_RATING} Stars
                  </span>
                </div>
                <Media lessThan="md">
                  <ProgressLine progress={`${progress}%`} color={color} />
                </Media>
              </div>
            );
          })}
        </div>
        {!!comments && (
          <div className={classes.comments}>
            <span className={classes.text}>Comments:</span>
            <p>{comments}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(Review);
