import { useState, useEffect } from 'react';
import WeatherConditions from '../utils/weatherConditions';

const fetchWeather = ({ city, state, country }) => {
  const url = `https://api.weatherapi.com/v1/forecast.json?key=67186c584095445a8ba203927210406&q=${encodeURIComponent(
    `${city} ${state} ${country}`
  )}&days=1&aqui=no`;

  const promise = new Promise((resolve, reject) => {
    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (!data?.forecast) {
          resolve(null);
          return;
        }

        const {
          forecast: { forecastday }
        } = data;

        const { day } = forecastday[0];
        const weatherCondition = WeatherConditions.find(
          condition => condition.code === day.condition.code
        );

        const weather = {
          maxTempC: day.maxtemp_c,
          maxTempF: day.maxtemp_f,
          minTempC: day.mintemp_c,
          minTempF: day.mintemp_f,
          description: day.condition.text,
          icon: weatherCondition?.icon
        };

        resolve(weather);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

  return promise;
};

const useWeather = property => {
  const [weather, setWeather] = useState({
    data: null,
    error: null,
    loading: true
  });

  useEffect(() => {
    if (!property) {
      setWeather({ data: null, error: null, loading: false });
      return;
    }

    fetchWeather(property)
      .then(data => {
        setWeather({ ...weather, data, loading: false });
      })
      .catch(error => {
        setWeather({ ...weather, error, loading: false });
      });
  }, [property]);

  return [weather];
};

export default useWeather;
