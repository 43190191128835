import React from 'react';
import { Menu, Dropdown } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import ImageButton from './Buttons/ImageButton';
import UnitsDropdown from './Dropdowns/UnitsDropdown';
import EllipsisText from './EllipsisText';
import Colors from '../utils/colors';
import { userSelector, ownerSelector } from '../selectors';
import { Media } from '../media';
import OWNER_ACTIONS from '../actions/ownerActions';

const useStyles = createUseStyles(
  {
    root: {
      alignItems: 'center'
    },
    welcome: {
      fontWeight: 'normal',
      color: Colors.grayThree
    }
  },
  { name: 'TopPanel' }
);

const TopPanel = ({ showMenu }) => {
  const classes = useStyles();
  const user = userSelector();
  const { selectedRoomId } = ownerSelector();
  const dispatch = useDispatch();

  const onChange = (_, { value }) => {
    dispatch({
      type: OWNER_ACTIONS.SELECT_ROOM_ID,
      payload: value
    });
  };

  return (
    <Menu className={classes.root} secondary>
      {!!showMenu && (
        <Menu.Item>
          <ImageButton
            style={{ padding: 5 }}
            src="/assets/menu.svg"
            onClick={showMenu}
          />
        </Menu.Item>
      )}
      <Media greaterThanOrEqual="md">
        <Menu.Item>
          <EllipsisText>
            <h3 className={classes.welcome}>
              Welcome, <strong>{user?.firstName}</strong>
            </h3>
          </EllipsisText>
        </Menu.Item>
      </Media>
      <Menu.Item position="right">
        <UnitsDropdown onChange={onChange} value={selectedRoomId} />
        <Dropdown item icon={{ name: 'user', circular: true, inverted: true }}>
          <Dropdown.Menu>
            <Link to="/owner/profile">
              <Dropdown.Item content="Profile" />
            </Link>
            <Link to="/sign-out">
              <Dropdown.Item content="Sign out" />
            </Link>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Item>
    </Menu>
  );
};

export default React.memo(TopPanel);
