import Colors from './colors';

export const RESERVATION_TYPES = {
  OWNER: 'OWNER',
  GUEST_OF_OWNER: 'GUEST_OF_OWNER',
  RENT: 'RENT',
  HOLD: 'HOLD',
  UNSPECIFIED: 'UNSPECIFIED',
  ROOM_BLOCK: 'ROOM_BLOCK',
  ROOM_BUFFER: 'ROOM_BUFFER',
  RATES: 'RATES'
};

export const EVENT_TYPES = {
  RESERVATION: 'reservation',
  MAINTENANCE: 'maintenance',
  DAILY: 'daily',
  REQUEST: 'request',
  ON_PROPERTY: 'onProperty',
  FRONT_DESK_NOTIFICATION: 'frontDeskNotification',
  RATES: 'rates'
};

export const TASK_ITEM_TYPES = {
  RESERVATION_RENTER: 'RENT',
  RESERVATION_OWNER: 'OWNER',
  RESERVATION_GUEST_OF_OWNER: 'GUEST_OF_OWNER',
  MAINTENANCE: 'maintenance',
  ON_PROPERTY: 'onProperty',
  FRONT_DESK_NOTIFICATION: 'frontDeskNotification',
  ROOM_BLOCK: 'ROOM_BLOCK',
  ROOM_BUFFER: 'ROOM_BUFFER',
  RATE: 'rate',
  HOLD: 'HOLD'
};

export const TOOLBAR_VIEWS = {
  WEEK: 'week',
  MONTH: 'month'
};

export const TASK_LABELS = {
  OWNER: 'Owner',
  GUEST_OF_OWNER: 'GuestOfOwner',
  MANDATORY: 'Mandatory',
  REQUESTED: 'Requested',
  RENT: 'Renter',
  HOLD: 'Hold',
  ROOM_BLOCK: 'Room Block',
  ROOM_BUFFER: 'Room Buffer'
};

export const OWNER_ROUTES = {
  root: '/owner/calendar'
};

export const EMPTY_RATING = { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 };
export const MAX_RATING = 5;
export const RATINGS = {
  0: { color: 'transparent', text: '--' },
  1: { color: Colors.redOne, text: 'Very Bad' },
  2: { color: Colors.orangeOne, text: 'Bad' },
  3: { color: Colors.lightBlueThree, text: 'Good' },
  4: { color: Colors.greenFour, text: 'Very Good' },
  5: { color: Colors.greenFive, text: 'Excellent' }
};

export const RESPONSE_STATUS = {
  OK: 200
};

export default {
  RESERVATION_TYPES,
  EVENT_TYPES,
  TOOLBAR_VIEWS,
  TASK_LABELS,
  OWNER_ROUTES,
  MAX_RATING,
  RATINGS,
  EMPTY_RATING,
  RESPONSE_STATUS
};
