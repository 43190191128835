import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Dimmer, Loader, Select } from 'semantic-ui-react';
import { orderBy } from 'lodash';
import useAxios from 'axios-hooks';
import useOwnerProtection from '../../hooks/useOwnerProtection';
import Tab from '../../components/Tab';
import Document from '../../components/Document';
import Colors from '../../utils/colors';
import Breakpoints from '../../utils/breakpoints';
import StringUtils from '../../utils/stringUtils';
import { ownerSelector } from '../../selectors';

const useStyles = createUseStyles(
  {
    root: {
      flex: 1,
      padding: '2rem'
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '1.5rem 0'
    },
    documents: {
      border: `1px solid ${Colors.grayTwo}`
    }
  },
  { name: 'OwnerDocumentsView' }
);

const OwnerDocumentsView = () => {
  useOwnerProtection();

  const classes = useStyles();
  const serverOrderKey = 'serverOrderIdx';
  const titleOrderKey = 'title';
  const [sort, setSort] = useState(serverOrderKey);
  const [sortOrder, setSortOrder] = useState('asc');
  const { selectedRoomId } = ownerSelector();

  const [categories, setCategories] = useState([]);
  const [documents, setDocuments] = useState([]);

  const [{ loading: loadingCategories }, listCategories] = useAxios(
    { url: '/api/listDocumentCategory', method: 'POST' },
    { manual: true }
  );

  const [{ loading: loadingDocuments }, listDocuments] = useAxios(
    { url: '/api/listDocument', method: 'POST' },
    { manual: true }
  );

  const updateDocumentList = async (categoryId, onlyUncategorized = null) => {
    const response = await listDocuments({
      data: { categoryId, onlyUncategorized, roomId: selectedRoomId }
    });

    const data = response.data.map((document, idx) => ({
      id: document.id,
      title: document.name,
      publishedDate: document.publishedDate,
      serverOrderIdx: idx,
      description: document.description
    }));

    setDocuments(data);
  };

  useEffect(async () => {
    if (selectedRoomId) {
      try {
        const response = await listCategories({
          data: { roomId: selectedRoomId }
        });

        const data = [
          ...response.data.map(category => ({
            id: category.id,
            name: category.name,
            onlyUncategorized: null
          })),
          ...[
            {
              id: null,
              name: 'Other',
              onlyUncategorized: true
            }
          ]
        ];

        await setCategories(data);
        const { id } = data[0];
        const onlyUncategorized = id !== 0 && !id;
        await updateDocumentList(id, onlyUncategorized);
      } catch (error) {
        console.error('There was an error in the request', { error });
      }
    }
  }, [selectedRoomId]);

  const onChangeSort = (_, { value }) => {
    if (value === serverOrderKey || value === titleOrderKey) {
      setSortOrder('asc');
    } else {
      setSortOrder('desc');
    }
    setSort(value);
  };

  const orderedDocuments = orderBy(documents, [sort], [sortOrder]);
  const items = categories.map(({ name: title }) => ({
    title,
    content: (
      <>
        <div className={classes.header}>
          <span>
            {StringUtils.numberToText(documents.length, 99)} Documents
          </span>
          <Select
            options={[
              {
                key: serverOrderKey,
                text: 'Sort by: Default',
                value: serverOrderKey
              },
              {
                key: 'publishedDate',
                text: 'Sort by: Published Date',
                value: 'publishedDate'
              },
              {
                key: titleOrderKey,
                text: 'Sort by: Title',
                value: titleOrderKey
              }
            ]}
            value={sort}
            onChange={onChangeSort}
          />
        </div>
        <div className={classes.documents}>
          {orderedDocuments.map(document => (
            <Document
              key={document.id}
              id={document.id}
              title={document.title}
              publishedDate={document.publishedDate}
              description={document.description}
            />
          ))}
        </div>
      </>
    )
  }));

  const loading = loadingCategories || loadingDocuments;

  return (
    <div className={classes.root}>
      <h1>Documents</h1>
      <Dimmer.Dimmable dimmed={loading}>
        <Dimmer active={loading} inverted>
          <Loader inverted content="Loading Documents..." />
        </Dimmer>
        <Tab
          items={items}
          onTabChange={async (_e, { activeIndex }) => {
            const { id, onlyUncategorized } = categories[activeIndex];
            await updateDocumentList(id, onlyUncategorized);
          }}
          maxWidth={Breakpoints.lg}
        />
      </Dimmer.Dimmable>
    </div>
  );
};

export default React.memo(OwnerDocumentsView);
