import React from 'react';
import { Tab as BaseTab } from 'semantic-ui-react';
import { createUseStyles } from 'react-jss';
import Colors from '../utils/colors';
import Breakpoints from '../utils/breakpoints';

const useStyles = createUseStyles(
  {
    root: maxWidth => ({
      '& > .ui.pointing.secondary.menu': {
        margin: 0,
        borderBottom: `1px solid ${Colors.grayTwo}`,
        '& > .item': {
          padding: '1rem 0',
          margin: '0 1.5rem -1px',
          '&:hover, &:focus': {
            borderColor: Colors.greenOne
          },
          '&.active': {
            borderColor: Colors.greenOne
          }
        },
        [`@media (max-width: ${maxWidth}px)`]: {
          flexDirection: 'column',
          '& > .item': {
            marginRight: 'auto'
          }
        }
      }
    })
  },
  { name: 'Tab' }
);

const Tab = ({ items, onTabChange, maxWidth = Breakpoints.md }) => {
  const classes = useStyles(maxWidth);
  const panes =
    items.length > 0 &&
    items.map(({ title, content }) => ({
      menuItem: title,
      render: () => <BaseTab.Pane as="div">{content}</BaseTab.Pane>
    }));

  return (
    <BaseTab
      className={classes.root}
      menu={{ secondary: true, pointing: true }}
      panes={panes}
      onTabChange={onTabChange}
      renderActiveOnly
    />
  );
};

export default React.memo(Tab);
