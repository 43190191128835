import React from 'react';
import { createUseStyles } from 'react-jss';
import { Image } from 'semantic-ui-react';
import classnames from 'classnames';
import Colors from '../../utils/colors';
import Breakpoints from '../../utils/breakpoints';

const REFERENCE_RESERVATIONS = {
  Owner: Colors.ownerEvent,
  'Guest of Owner': Colors.guestOfOwnerEvent,
  Renter: Colors.renterEvent,
  Maintenance: Colors.maintenanceEvent,
  Buffer: Colors.bufferEvent,
  'Group Hold': Colors.groupHoldEvent
};

const REFERENCE_EVENTS = {
  Reservation: '/assets/calendar_check_icon.svg',
  Maintenance: '/assets/maintenance_icon.svg',
  Request: '/assets/user_icon.svg',
  'On Property Events': '/assets/calendar_star_icon.svg',
  'Front Desk Notification': '/assets/bell_icon.svg'
};

const useStyles = createUseStyles(
  {
    root: {
      display: 'flex',
      alignItems: 'center',
      padding: '1rem',
      borderTop: `1px solid ${Colors.grayTwo}`,
      borderRight: `1px solid ${Colors.grayTwo}`,
      borderLeft: `1px solid ${Colors.grayTwo}`
    },
    item: {
      display: 'inline-flex',
      margin: '0.5rem 1rem 0.5rem 0',
      '& > *': {
        marginRight: '0.5rem'
      }
    },
    color: {
      display: 'inline-block',
      borderRadius: '3px',
      width: '16px',
      height: '16px'
    },
    [`@media (max-width: ${Breakpoints.xl}px)`]: {
      root: {
        flexDirection: 'column'
      },
      item: {
        width: '100%'
      }
    }
  },
  { name: 'Legend' }
);

const Reservations = React.memo(({ className }) => {
  const classes = useStyles();

  return (
    <div className={classnames(classes.root, className)}>
      {Object.keys(REFERENCE_RESERVATIONS).map(reference => (
        <span key={reference} className={classes.item}>
          <span
            className={classes.color}
            style={{ backgroundColor: REFERENCE_RESERVATIONS[reference] }}
          />
          {reference}
        </span>
      ))}
    </div>
  );
});

const Events = React.memo(({ className, children }) => {
  const classes = useStyles();

  return (
    <div className={classnames(classes.root, className)}>
      {Object.keys(REFERENCE_EVENTS).map(reference => (
        <span key={reference} className={classes.item}>
          <Image src={REFERENCE_EVENTS[reference]} />
          {reference}
        </span>
      ))}
      {children}
    </div>
  );
});

export default { Reservations, Events };
