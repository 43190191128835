import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { round, sum, sumBy } from 'lodash';
import Rating from './Rating';
import Colors from '../utils/colors';
import Breakpoints from '../utils/breakpoints';
import { MAX_RATING, RATINGS } from '../utils/constants';
import StringUtils from '../utils/stringUtils';

const RADIUS = 53;
const PARTIAL_CIRCLE = 75;
const CIRCUMFERENCE = 2 * Math.PI * RADIUS;
const CIRCLE = (PARTIAL_CIRCLE / 100) * CIRCUMFERENCE;
const STROKE_WIDTH = 12;

const text = {
  fontSize: 12,
  fontWeight: 500,
  color: Colors.grayThree
};

const useStyles = createUseStyles(
  {
    root: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      '& > div': {
        margin: '0 auto'
      }
    },
    chart: {
      position: 'relative',
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: 150
    },
    key: {
      extend: text,
      marginRight: '1rem',
      minWidth: 52,
      '& > span:first-child': {
        display: 'inline-block',
        width: 20,
        textAlign: 'right',
        marginRight: 5
      }
    },
    bars: {
      display: 'flex',
      flexDirection: 'column',
      '& > div': {
        display: 'inline-flex',
        alignItems: 'center'
      }
    },
    bar: {
      height: 5,
      width: 160,
      marginLeft: 'auto',
      backgroundColor: Colors.whiteTwo,
      borderRadius: 4
    },
    progress: {
      display: 'block',
      height: '100%',
      borderRadius: 4,
      backgroundColor: 'transparent',
      width: '0%',
      transition: 'width 500ms linear'
    },
    circle: {
      transform: 'rotate(135deg)',
      marginBottom: '-1.5rem',
      width: '100%',
      height: '100%',
      '& > .complete': {
        transition: 'stroke-dasharray 500ms linear'
      }
    },
    score: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'absolute',
      top: '60px'
    },
    number: {
      fontSize: 34,
      fontWeight: 'bold',
      marginBottom: '0.5rem'
    },
    text: {
      extend: text
    },
    ratings: {
      extend: text,
      color: 'black'
    },
    [`@media (max-width: ${Breakpoints.md}px)`]: {
      root: {
        flexDirection: 'column',
        '& > div:first-child': {
          margin: '0 2rem 2rem'
        }
      }
    }
  },
  { name: 'UnitAverageScore' }
);

const UnitAverageScore = ({ ratings, legend, includeBars = true }) => {
  const [ready, setReady] = useState(false);
  const classes = useStyles();

  const total = sum(Object.values(ratings));
  const keys = Object.keys(ratings).sort().reverse();

  const stars = sumBy(keys, key => key * ratings[key]);
  const score = stars / total || 0;

  const rating = RATINGS[round(score)];
  const porcentage = (score * 100) / MAX_RATING;
  const complete = (porcentage / 100) * CIRCLE;

  useEffect(() => {
    setReady(false);
    setTimeout(() => setReady(true), 500);
  }, [ratings]);

  return (
    <div className={classes.root}>
      <div className={classes.chart}>
        <svg className={classes.circle} xmlns="http://www.w3.org/2000/svg">
          <circle
            r={RADIUS}
            cx="50%"
            cy="50%"
            stroke={Colors.whiteTwo}
            fill="none"
            strokeWidth={STROKE_WIDTH}
            strokeLinecap="round"
            strokeDasharray={`${CIRCLE}, ${CIRCUMFERENCE}`}
          />
          <circle
            className="complete"
            r={RADIUS}
            cx="50%"
            cy="50%"
            stroke={rating.color}
            fill="none"
            strokeLinecap="round"
            strokeWidth={ready ? STROKE_WIDTH : ''}
            strokeDasharray={`${ready ? complete : 0}, ${CIRCUMFERENCE}`}
          />
        </svg>
        <div className={classes.score}>
          <span className={classes.number}>{round(score, 1)}</span>
          <span className={classes.text}>{rating.text}</span>
        </div>
        <Rating size="small" score={round(score)} />
        <small className={classes.ratings}>
          {legend || `${StringUtils.numberToText(total)} ratings`}
        </small>
      </div>
      {!!includeBars && (
        <div className={classes.bars}>
          {keys.map(key => (
            <div key={key}>
              <div className={classes.key}>
                <span>{key}.0</span>
                <span>({StringUtils.numberToText(ratings[key])})</span>
              </div>
              <div className={classes.bar}>
                <div
                  className={classes.progress}
                  style={
                    ready
                      ? {
                          backgroundColor: RATINGS[key].color,
                          width: `${(ratings[key] * 100) / total}%`
                        }
                      : {}
                  }
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default React.memo(UnitAverageScore);
