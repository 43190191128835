import ACTIONS from '../actions/authAction';

const initialState = {
  user: null,
  token: null
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SIGN_IN: {
      return {
        ...state,
        ...action.payload
      };
    }
    case ACTIONS.SIGN_OUT: {
      return {
        ...state,
        user: null,
        token: null
      };
    }
    case ACTIONS.UPDATE_USER: {
      const { user } = state;
      return {
        ...state,
        user: {
          ...user,
          ...action.payload
        }
      };
    }
    default: {
      return state;
    }
  }
};

export default authReducer;
