import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { Dimmer, Loader } from 'semantic-ui-react';
import useAxios from 'axios-hooks';
import { ownerSelector } from '../../selectors';
import Request from '../../components/Request';
import useOwnerProtection from '../../hooks/useOwnerProtection';

const useStyles = createUseStyles(
  {
    root: {
      flex: 1,
      padding: '2rem'
    }
  },
  { name: 'OwnerSupportAndRequestsView' }
);

const OwnerSupportAndRequestsView = () => {
  useOwnerProtection();

  const classes = useStyles();
  const { selectedRoomId } = ownerSelector();
  const [{ data = [], loading }, execute] = useAxios(
    {
      url: '/api/listTile',
      method: 'POST'
    },
    { manual: true }
  );

  useEffect(() => {
    if (selectedRoomId) {
      execute({ data: { roomId: selectedRoomId } });
    }
  }, [selectedRoomId]);

  return (
    <div className={classes.root}>
      <h1>Owner Support and Requests</h1>
      <Dimmer.Dimmable dimmed={loading}>
        <Dimmer active={loading} inverted>
          <Loader inverted content="Loading Requests..." />
        </Dimmer>
        {data.map(({ id, ...props }) => (
          <Request key={id} tileId={id} roomId={selectedRoomId} {...props} />
        ))}
      </Dimmer.Dimmable>
    </div>
  );
};

export default React.memo(OwnerSupportAndRequestsView);
