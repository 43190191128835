import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Dimmer, Loader, Message } from 'semantic-ui-react';
import useAxios from 'axios-hooks';
import UnitAverageScore from '../../components/UnitAverageScore';
import QuestionInsight from '../../components/QuestionInsight';
import Insight from '../../components/Insight';
import Box from '../../components/Box';
import IndividualUnitReviewsTab from '../../components/IndividualUnitReviewsTab';
import { ownerSelector } from '../../selectors';
import Breakpoints from '../../utils/breakpoints';
import DateUtils from '../../utils/dateUtils';
import { EMPTY_RATING } from '../../utils/constants';
import useOwnerProtection from '../../hooks/useOwnerProtection';

const MAX_ITEMS_PER_ROW_XL = 3;
const MAX_ITEMS_PER_ROW_LG = 2;
const MAX_ITEMS_PER_ROW_MD = 1;

const MAX_QUESTION_INSIGHTS_PER_ROW = 4;
const MAX_QUESTION_INSIGHTS_PER_ROW_XXL = 4;
const MAX_QUESTION_INSIGHTS_PER_ROW_XL = 2;

const useStyles = createUseStyles(
  {
    root: {
      flex: 1,
      padding: '2rem'
    },
    scores: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: '2rem',
      '& > *': {
        width: 'calc(50% - 1rem)'
      },
      [`@media (max-width: ${Breakpoints.lg}px)`]: {
        flexDirection: 'column',
        '& > *': {
          width: '100%',
          marginBottom: '2rem'
        }
      }
    },
    score: {
      padding: '2rem'
    },
    questionInsights: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: '-1px'
    },
    questionInsight: {
      flexBasis: `${100 / MAX_QUESTION_INSIGHTS_PER_ROW}%`
    },
    insights: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: '-1px'
    },
    insight: {
      flexBasis: `${100 / MAX_ITEMS_PER_ROW_XL}%`
    },
    [`@media (max-width: ${Breakpoints.xxl}px)`]: {
      questionInsight: {
        flexBasis: `${100 / MAX_QUESTION_INSIGHTS_PER_ROW_XXL}%`
      }
    },
    [`@media (max-width: ${Breakpoints.xl}px)`]: {
      questionInsight: {
        flexBasis: `${100 / MAX_QUESTION_INSIGHTS_PER_ROW_XL}%`
      }
    },
    [`@media (max-width: ${Breakpoints.lg}px)`]: {
      insight: {
        flexBasis: `${100 / MAX_ITEMS_PER_ROW_LG}%`
      }
    },
    [`@media (max-width: ${Breakpoints.md}px)`]: {
      insight: {
        flexBasis: `${100 / MAX_ITEMS_PER_ROW_MD}%`
      }
    }
  },
  { name: 'OwnerGuestSurveysView' }
);

const INITIAL_DATA = {
  unitScores: EMPTY_RATING,
  propertyScores: EMPTY_RATING,
  questionInsights: [],
  unitReviews: [],
  positiveInsights: [],
  negativeInsights: []
};

const getRatingsObject = data =>
  data?.reduce(
    (obj, item) => {
      obj[item.value] = item.quantity;
      return obj;
    },
    { ...EMPTY_RATING }
  );

const mapInsights = (insights, defaultDescription) =>
  insights.map(
    ({
      id,
      name,
      url,
      count,
      subText,
      recomendationLink,
      recomendationText
    }) => ({
      id,
      image: url,
      title: name,
      recomendationLink,
      recomendationText,
      description: `${count} ${
        subText ? `Guest(s) ${subText}` : defaultDescription
      }`
    })
  );

const onGetGuestSurveysSuccess = ({ data }) => {
  const promise = new Promise(resolve => {
    if (!data) {
      resolve(INITIAL_DATA);
      return;
    }

    const {
      roomAverageStarDetail,
      propertyAverageStarDetail,
      questionInsightsList,
      individualUnitReviewList,
      positiveInsightsList,
      negativeInsightsList
    } = data;

    const unitScores = getRatingsObject(roomAverageStarDetail);
    const propertyScores = getRatingsObject(propertyAverageStarDetail);
    const questionInsights = questionInsightsList.map(
      ({ question, starDetail }, index) => ({
        key: `question-${index}`,
        text: question,
        ratings: getRatingsObject(starDetail)
      })
    );

    const unitReviews = individualUnitReviewList.map(
      (
        { name, checkIn, checkOut, answers, overallRating = '0.0', comments },
        reviewIndex
      ) => ({
        key: `review-${reviewIndex}`,
        user: name,
        checkIn: DateUtils.toDate(checkIn),
        checkOut: DateUtils.toDate(checkOut),
        overallRating: Number(overallRating) || 0,
        comments,
        questions: answers.map(({ question, response }, questionIndex) => ({
          key: `review-${reviewIndex}-question-${questionIndex}`,
          text: question,
          score: Number(response) || 0
        }))
      })
    );

    const positiveInsights = mapInsights(
      positiveInsightsList,
      'Guests have Complimented'
    );

    const negativeInsights = mapInsights(
      negativeInsightsList,
      'Guests have Recommended'
    );

    resolve({
      ...INITIAL_DATA,
      unitScores,
      propertyScores,
      questionInsights,
      unitReviews,
      positiveInsights,
      negativeInsights
    });
  });

  return promise;
};

const OwnerGuestSurveysView = () => {
  useOwnerProtection();

  const classes = useStyles();
  const { selectedRoomId, roomInfoList } = ownerSelector();

  const [state, setState] = useState({
    loading: false,
    data: INITIAL_DATA,
    surveysUnavailable: false
  });

  const [, execute] = useAxios(
    {
      url: '/api/guestSurveys',
      method: 'POST'
    },
    { manual: true }
  );

  useEffect(() => {
    const selectedRoom = roomInfoList.find(
      ({ id, showSurveys }) => id === selectedRoomId && showSurveys
    );

    if (selectedRoom) {
      setState({
        loading: true,
        data: INITIAL_DATA,
        surveysUnavailable: false
      });
      execute({ data: { roomId: selectedRoomId } })
        .then(onGetGuestSurveysSuccess)
        .then(data => {
          setState({ loading: false, data, surveysUnavailable: false });
        });
    } else {
      setState({
        loading: false,
        data: INITIAL_DATA,
        surveysUnavailable: true
      });
    }
  }, [selectedRoomId]);

  const {
    loading,
    data: {
      unitScores,
      propertyScores,
      questionInsights,
      unitReviews,
      positiveInsights,
      negativeInsights
    },
    surveysUnavailable
  } = state;

  if (surveysUnavailable) {
    return (
      <div className={classes.root}>
        <h1>Guest Surveys</h1>
        <Message warning>
          <Message.Header>Unavailable</Message.Header>
          <p>No surveys available for this room</p>
        </Message>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <h1>Guest Surveys</h1>
      <Dimmer.Dimmable dimmed={loading}>
        <Dimmer active={loading} inverted>
          <Loader inverted content="Loading Guest Surveys..." />
        </Dimmer>
        <Box title="Why Guests Love Your Unit" image="/assets/compliments.svg">
          <div className={classes.insights}>
            {positiveInsights.map(insight => (
              <Insight
                key={insight.id}
                className={classes.insight}
                title={insight.title}
                description={insight.description}
                image={insight.image}
                recommendationLink={insight.recomendationLink}
                recommendationText={insight.recomendationText}
              />
            ))}
          </div>
        </Box>
        <Box
          title="Opportunities for Improvement"
          image="/assets/thumb_down.svg"
        >
          <div className={classes.insights}>
            {negativeInsights.map(insight => (
              <Insight
                key={insight.id}
                className={classes.insight}
                title={insight.title}
                description={insight.description}
                image={insight.image}
                recommendationLink={insight.recomendationLink}
                recommendationText={insight.recomendationText}
              />
            ))}
          </div>
        </Box>
        <div className={classes.scores}>
          <Box title="Your Unit Average Score">
            <div className={classes.score}>
              <UnitAverageScore ratings={unitScores} />
            </div>
          </Box>
          <Box title="Your Property Average Score">
            <div className={classes.score}>
              <UnitAverageScore ratings={propertyScores} />
            </div>
          </Box>
        </div>
        <Box title="Question Insights">
          <div className={classes.questionInsights}>
            {questionInsights.map(questionInsight => (
              <QuestionInsight
                key={questionInsight.key}
                className={classes.questionInsight}
                text={questionInsight.text}
                ratings={questionInsight.ratings}
              />
            ))}
          </div>
        </Box>
        <Box title="Individual Unit Reviews" separator={false}>
          <IndividualUnitReviewsTab reviews={unitReviews} />
        </Box>
      </Dimmer.Dimmable>
    </div>
  );
};

export default React.memo(OwnerGuestSurveysView);
