import Axios from 'axios';
import store from './store';

const axios = Axios.create();

axios.interceptors.request.use(
  async config => {
    const {
      auth: { token }
    } = store.getState();

    if (token) {
      config.headers['Ownerportal-Token'] = token;
    }

    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  error => Promise.reject(error)
);

axios.interceptors.response.use(
  response => response,
  async error => {
    const { config } = error;

    if (error.response?.status === 401) {
      window.location = '/unauthorized';
      return axios(config);
    }

    if (error.response?.status === 404) {
      window.location = '/notfound';
      return axios(config);
    }

    return Promise.reject(error);
  }
);

export default axios;
