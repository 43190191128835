import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, authorized, ...rest }) => {
  const render = props => {
    if (authorized) {
      return <Component {...rest} {...props} />;
    }

    return (
      <Redirect
        to={{ pathname: '/unauthorized', state: { from: props.location } }}
      />
    );
  };

  return <Route {...rest} render={render} />;
};

export default React.memo(ProtectedRoute);
