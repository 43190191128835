import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { Image } from 'semantic-ui-react';
import classnames from 'classnames';
import Colors from '../utils/colors';

const EMPTY_IMAGE_SRC =
  'data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

const useStyles = createUseStyles(
  {
    root: {
      padding: '0 1.5rem'
    },
    content: {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      padding: '1.5rem 0',
      borderBottom: `1px solid ${Colors.grayTwo}`
    },
    image: {
      display: 'inline !important',
      padding: 10,
      marginRight: '1.5rem',
      maxWidth: '100%',
      maxHeight: '100%',
      minWidth: 46,
      minHeight: 46
    },
    imageBkg: {
      display: 'inline !important',
      marginRight: '1.5rem',
      borderRadius: '50%',
      backgroundColor: `${Colors.grayTwo} !important`,
      width: 46,
      height: 46
    },
    description: {
      '& > h4': {
        fontSize: 14,
        fontWeight: 'bolder',
        margin: 0
      },
      '& > small': {
        fontSize: 12,
        color: Colors.grayFour
      }
    },
    link: {
      display: 'block'
    }
  },
  { name: 'Insight' }
);

const Insight = ({
  className,
  title,
  description,
  image,
  recommendationText,
  recommendationLink
}) => {
  const classes = useStyles();
  const [imageSrc, setImageSrc] = useState(EMPTY_IMAGE_SRC);

  useEffect(() => {
    const img = new window.Image();
    img.onload = () => setImageSrc(image);
    img.src = image;
  }, [image]);

  const hasRecommendation = !!recommendationText && !!recommendationLink;

  return (
    <div className={classnames(classes.root, className)}>
      <div className={classes.content}>
        <div className={classes.imageBkg}>
          <Image className={classes.image} src={imageSrc} />
        </div>
        <div className={classes.description}>
          <h4>{title}</h4>
          <small>{description}</small>
          <small>
            {hasRecommendation && (
              <a
                className={classes.link}
                href={recommendationLink}
                target="_blank"
                rel="noreferrer"
              >
                {recommendationText}
              </a>
            )}
          </small>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Insight);
