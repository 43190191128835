import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Message } from 'semantic-ui-react';
import useAxios from 'axios-hooks';
import Joi from 'joi';
import validateSchema from '../utils/validateSchema';
import { RESPONSE_STATUS } from '../utils/constants';
import useModalState from '../hooks/useModalState';
import ModalForm from './ModalForm';

const forgotPasswordSchema = Joi.object({
  email: Joi.string()
    .email({ minDomainSegments: 2, tlds: { allow: ['com', 'net'] } })
    .required()
});

const ForgotPassword = ({ trigger }) => {
  const { isOpen, onOpen, onClose } = useModalState();
  const [errors, setErrors] = useState({ email: null });
  const [data, setData] = useState({ email: '' });
  const [message, setMessage] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    setErrors({ email: null });
    setData({ email: '' });
    setMessage(null);
  }, [isOpen]);

  const [{ loading }, execute] = useAxios(
    {
      url: '/api/resetPassword',
      method: 'POST'
    },
    { manual: true }
  );

  const onError = () => {
    setMessage({
      header: 'Something Went Wrong',
      content: 'Please, try again later',
      error: true
    });
  };

  const onSuccess = ({ status }) => {
    if (status === RESPONSE_STATUS.OK) {
      setSubmitted(true);
      setMessage({
        header: 'Success',
        content: 'You will receive an email with a temporary password',
        success: true
      });
    }
  };

  const onSubmit = () => {
    validateSchema(forgotPasswordSchema, data)
      .then(() => {
        execute({ data }).then(onSuccess).catch(onError);
      })
      .catch(setErrors);
  };

  const { email } = data;

  const onChange = (_event, { id, value }) => {
    setErrors({ ...errors, [id]: null });
    setData({ ...data, [id]: value });
  };

  return (
    <ModalForm.Content
      open={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      trigger={trigger}
      title="Forget Password"
    >
      <Form loading={loading} onSubmit={onSubmit}>
        {!!message && <Message {...message} visible />}
        <Form.Field
          id="email"
          control={Input}
          label="Email"
          placeholder="Email"
          type="string"
          value={email}
          onChange={onChange}
          error={errors.email}
        />
        <ModalForm.Actions>
          {!submitted ? (
            <>
              <Button color="red" onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit" primary>
                Send
              </Button>
            </>
          ) : (
            <Button onClick={onClose}> Close </Button>
          )}
        </ModalForm.Actions>
      </Form>
    </ModalForm.Content>
  );
};

export default React.memo(ForgotPassword);
