import React from 'react';
import { createUseStyles } from 'react-jss';
import { Rating as BaseRating } from 'semantic-ui-react';
import { MAX_RATING, RATINGS } from '../utils/constants';

const useStyles = createUseStyles(
  {
    root: rating => ({
      '&.ui.star.rating > i': {
        textShadow: 'none !important'
      },
      '&.ui.star.rating > i.active': {
        color: `${rating.color} !important`,
        textShadow: 'none !important'
      }
    })
  },
  { name: 'Rating' }
);

const Rating = ({ score, size = 'small' }) => {
  const rating = RATINGS[score];
  const classes = useStyles(rating);

  return (
    <BaseRating
      className={classes.root}
      icon="star"
      size={size}
      rating={score}
      maxRating={MAX_RATING}
      clearable={false}
      disabled
    />
  );
};

export default React.memo(Rating);
