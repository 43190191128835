import React from 'react';
import useAxios from 'axios-hooks';
import TaskItem from './TaskItem';
import DateUtils from '../../utils/dateUtils';

const RateTask = ({ rateItem, onUpdate: onUpdateSuccess }) => {
  const {
    rate,
    minLOS,
    allowMinLOSModification,
    allowRateModification,
    start,
    ratePlanItemId
  } = rateItem;

  const [, editRate] = useAxios(
    {
      url: '/api/editRate',
      method: 'POST'
    },
    { manual: true }
  );

  const onSubmit = rateData => {
    const payload = {
      ratePlanItemId,
      date: start,
      modifiedRate: rateData.rate,
      modifiedMinLOS: rateData.minLOS,
      allowRateModification: rateData.allowRateModification,
      allowMinLOSModification: rateData.allowMinLOSModification
    };
    return new Promise((resolve, reject) =>
      editRate({ data: payload })
        .then(({ data }) => {
          resolve({
            date: data.startDate,
            title: 'Rate',
            label: 'Rate',
            ...data
          });
          onUpdateSuccess(DateUtils.toDate(DateUtils.addDay(start)));
          onUpdateSuccess(start);
        })
        .catch(error => {
          reject(error);
        })
    );
  };

  return (
    <TaskItem
      id={ratePlanItemId}
      title="Rate"
      taskItemType="rate"
      date={start}
      rate={rate}
      minLOS={minLOS}
      allowMinLOSModification={allowMinLOSModification}
      allowRateModification={allowRateModification}
      readOnly={!(!!allowMinLOSModification || !!allowRateModification)}
      onSubmit={onSubmit}
    />
  );
};

export default React.memo(RateTask);
