import React, { useEffect } from 'react';
import { configure } from 'axios-hooks';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import axios from './axios';
import store from './store';
import persistor from './persistor';
import { MediaContextProvider } from './media';
import buildInfo from './buildInfo';
import OwnerPanelRoutes from './routes/OwnerPanelRoutes';
import LoginView from './views/LoginView';
import SignOutView from './views/SignOutView';
import UnauthorizedView from './views/UnauthorizedView';
import NotFoundView from './views/NotFoundView';

configure({ axios });

const AppLoader = () => (
  <Loader id="full-screen-loader" size="big" indeterminate>
    Preparing app...
  </Loader>
);

const App = () => {
  useEffect(() => {
    const { buildDate, buildVersion } = buildInfo;
    console.log(`Build Number: ${buildVersion}`);
    console.log(`Build Date: ${new Date(buildDate).toString()}`);
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={AppLoader} persistor={persistor}>
        <MediaContextProvider>
          <Router basename={`${process.env.PUBLIC_URL}`}>
            <Switch>
              <Route path="/" exact component={LoginView} />
              <Route path="/sign-out" exact component={SignOutView} />
              <Route path="/unauthorized" exact component={UnauthorizedView} />
              <Route path="/notfound" exact component={NotFoundView} />
              <Route path="/owner/:path?" exact component={OwnerPanelRoutes} />
              <Redirect to="/notfound" />
            </Switch>
          </Router>
        </MediaContextProvider>
      </PersistGate>
    </Provider>
  );
};

export default React.memo(App);
