import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { userSelector } from '../selectors';

const useOwnerProtection = () => {
  const user = userSelector();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location?.pathname !== '/owner/profile' && user?.changePassword) {
      history.push('/owner/profile');
    }

    return false;
  });
};

export default useOwnerProtection;
